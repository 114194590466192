/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import SGColumnHeader from '../../../../../../common/components/grids/SGColumnHeader';

import useSortableGrid from '../../../../../../common/utils/UseSortableGrid';
import SGConstants from '../../../../../../common/utils/SGConstants';
import { formatDate } from '../../../../../../common/utils/DateFunctions';

import global from '../../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ learnToSwimReg, onRegistrationClicked }) => (
  <tr>
    <td><a onClick={e => onRegistrationClicked(e, learnToSwimReg)}>{learnToSwimReg.entityName || ''}</a></td>
    <td>{learnToSwimReg.tradeName || ''}</td>
    <td>{learnToSwimReg.contactFirstName || ''} {learnToSwimReg.contactLastName || ''}</td>
    <td>{learnToSwimReg.contactEmail || ''}</td>
    <td>{learnToSwimReg.submissionDate ? formatDate(learnToSwimReg.submissionDate) : ''}</td>
  </tr>
);

const LearnToSwimRegistrationGridLarge = ({ data, isLoading, onRegistrationClicked }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(data);

  return (
    <table className={[global.UsasTable, 'visible-lg visible-md'].join(' ')}>
      <thead>
        <tr>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Business Legal Name'} columnField={'entityName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Trade Name/DBA'} columnField={'tradeName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Contact'} columnField={'contactLastName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Contact Email'} columnField={'contactEmail'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Submission Date'} columnField={'submissionDate'}
            sortType={SGConstants.SORT_TYPE_DATE} onColumnClicked={onColumnClicked} />
        </tr>
      </thead>
      <tbody>
        {isLoading === true
          ? <tr><td colSpan="5">Loading...</td></tr>
          : sortableGridState.sortedGridData.length > 0
            ? sortableGridState.sortedGridData.map((learnToSwimReg, i) => <GridRow key={i} learnToSwimReg={learnToSwimReg} onRegistrationClicked={onRegistrationClicked} />)
            : <tr><td colSpan="5">No Learn To Swim Provider Registrations</td></tr>
        }
      </tbody>
    </table>
  );
};

export default LearnToSwimRegistrationGridLarge;