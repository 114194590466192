import { BASIC_INITIAL_STATE } from '../../../../common/utils/HttpHelper';

import getPreCompetitiveRegistration from './GetPreCompetitiveRegistrationData';
import getPreCompetitiveRegistrationWorkflow from '../GetWorkFlowByIdData';
import putPreCompetitiveRegistrationWorkflow from '../PutWorkFlowData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const PreCompetitiveRegistrationData = {
  INITIAL_STATE,
  getPreCompetitiveRegistration,
  getPreCompetitiveRegistrationWorkflow,
  putPreCompetitiveRegistrationWorkflow
};

export default PreCompetitiveRegistrationData;