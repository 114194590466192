/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import SGColumnHeader from '../../../../../../common/components/grids/SGColumnHeader';

import { formatDate } from '../../../../../../common/utils/DateFunctions';
import useSortableGrid from '../../../../../../common/utils/UseSortableGrid';
import SGConstants from '../../../../../../common/utils/SGConstants';

import global from '../../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ meetSanc, onSanctionClicked }) => (
  <tr>
    <td><a onClick={e => onSanctionClicked(e, meetSanc)}>{`${meetSanc.lscCode || ''} - ${meetSanc.clubName || ''}/${meetSanc.clubCode || ''}`}</a></td>
    <td>{formatDate(meetSanc.submissionDate)}</td>
    <td>{meetSanc.meetName}</td>
    <td>{`${formatDate(meetSanc.meetStartDate)} - ${formatDate(meetSanc.meetEndDate)}`}</td>
  </tr>
);

const LargeGrid = ({ data, isLoading, onSanctionClicked }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(data);

  return (
    <table className={[global.UsasTable, 'visible-lg visible-md'].join(' ')}>
      <thead>
        <tr>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'LSC - Club Name/Club Code'} columnField={'lscCode'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Submission Date'} columnField={'submissionDate'}
            sortType={SGConstants.SORT_TYPE_DATE} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Meet Name'} columnField={'meetName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <th>Meet Dates</th>
        </tr>
      </thead>
      <tbody>
        {isLoading === true
          ? <tr><td colSpan="4">Loading...</td></tr>
          : sortableGridState.sortedGridData.length > 0
            ? sortableGridState.sortedGridData.map((meetSanc, i) => <GridRow key={i} meetSanc={meetSanc} onSanctionClicked={onSanctionClicked} />)
            : <tr><td colSpan="4">No Meet Sanction Requests</td></tr>
        }
      </tbody>
    </table>
  );
};

export default LargeGrid;