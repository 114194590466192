import { BASIC_INITIAL_STATE } from '../../../../common/utils/HttpHelper';

import getSafeSportRequestData from './GetSafeSportRequestData';
import getSafeSportWorkflowData from '../GetWorkFlowByIdData';
import putSafeSportWorkflowData from '../PutWorkFlowData';

const SafeSportData = {
  INITIAL_STATE: { ...BASIC_INITIAL_STATE },
  getSafeSportRequestData,
  getSafeSportWorkflowData,
  putSafeSportWorkflowData
};

export default SafeSportData;