import { useState } from 'react';

import PowerBiReportsData from './PowerBiReportsData';
import useSecurityData from '../security/UseSecurityData';

const usePreCompOrganizationData = () => {
  const { securityState, userInfo } = useSecurityData();
  const [powerBiReportState, setPowerBiReportState] = useState(PowerBiReportsData.INITIAL_STATE);


  const getReportCategory = (reportCategoryId) => {
    PowerBiReportsData.getReportCategoryData(reportCategoryId, powerBiReportState, setPowerBiReportState);
  };

  const getReportsForCurrentUserData = () => {
    PowerBiReportsData.getReportsForCurrentUserData(powerBiReportState, setPowerBiReportState);
  };

  const getReportData = (reportId) => {
    PowerBiReportsData.getReportData(reportId, powerBiReportState, setPowerBiReportState);
  };

  const getReportForLsc = (reportId) => {
    if (securityState.isObjLoaded) {
      const orgUnitId = userInfo?.primaryOrgUnitId;
      PowerBiReportsData.getReportForLscData(orgUnitId, reportId, powerBiReportState, setPowerBiReportState);
    }
  };

  const getReportForClub = (reportId) => {
    if (securityState.isObjLoaded) {
      const orgUnitId = userInfo?.primaryOrgUnitId;
      PowerBiReportsData.getReportForClubData(orgUnitId, reportId, powerBiReportState, setPowerBiReportState);
    }
  };

  const getReportForAdmin = (reportId) => {
    PowerBiReportsData.getReportForAdminData(reportId, powerBiReportState, setPowerBiReportState)
  };

  const clearObjData = () => {
    setPowerBiReportState({
      ...powerBiReportState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {}
    });
  };

  const clearArrayData = () => {
    setPowerBiReportState({
      ...powerBiReportState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: []
    });
  };

  return {
    powerBiReportState,
    clearObjData,
    clearArrayData,
    getReportCategory,
    getReportsForCurrentUserData,
    getReportData,
    getReportForLsc,
    getReportForClub,
    getReportForAdmin
  };
};

export default usePreCompOrganizationData;