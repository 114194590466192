import { BASIC_INITIAL_STATE } from '../../../../common/utils/HttpHelper';

import getMeetSanction from './GetMeetSanctionData';
import getMeetSanctionWorkflow from '../GetWorkFlowByIdData';
import putMeetSanctionWorkflow from '../PutWorkFlowData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const MeetSanctionData = {
  INITIAL_STATE,
  getMeetSanction,
  getMeetSanctionWorkflow,
  putMeetSanctionWorkflow
};

export default MeetSanctionData;