import { useState } from 'react';
import ApprovalQueuesData from './ApprovalQueuesData';

const useApprovalQueuesData = () => {
  const [ approvalQueuesState, setApprovalQueuesState ] = useState(ApprovalQueuesData.INITIAL_STATE);

  const getQueueStatus = () => {
    ApprovalQueuesData.getQueueStatus(approvalQueuesState, setApprovalQueuesState);
  };
  
  return { approvalQueuesState, getQueueStatus };
};

export default useApprovalQueuesData;