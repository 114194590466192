import useNavRoutes from '../../../../../common/state/security/UseNavRoutes';
import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

const useWorkFlowNavigation = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();

  const onReturnClicked = (e) => {
    e?.preventDefault();

    if (navRoutes.WORKFLOWS_QUEUE_LIST?.route) {
      navigate(navRoutes.WORKFLOWS_QUEUE_LIST?.route);
    }
  };
  return {
    navRoutes,
    onReturnClicked
  };
};

export default useWorkFlowNavigation;