import { useState } from 'react';

import NewClubRegistrationData from './NewClubRegistrationData';

const UseNewClubRegistrationData = () => {
  const [newClubRegistrationState, setNewClubRegistrationState] = useState(NewClubRegistrationData.INITIAL_STATE);

  const getNewClubRegistration = () => {
    NewClubRegistrationData.getNewClubRegistration(newClubRegistrationState, setNewClubRegistrationState);
  };

  const getNewClubRegistrationWorkflow = (workflowId) => {
    NewClubRegistrationData.getNewClubRegistrationWorkflow(workflowId, newClubRegistrationState, setNewClubRegistrationState);
  };

  const putNewClubRegistrationWorkflow = (workflowId, workflowObj) => {
    NewClubRegistrationData.putNewClubRegistrationWorkflow(workflowId, workflowObj, newClubRegistrationState, setNewClubRegistrationState);
  };

  const clearObjData = () => {
    setNewClubRegistrationState({
      ...newClubRegistrationState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {}
    });
  };

  const clearArrayData = () => {
    setNewClubRegistrationState({
      ...newClubRegistrationState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: []
    });
  };

  const confirmSave = () => {
    setNewClubRegistrationState({
      ...newClubRegistrationState,
      isSaved: false
    });
  };

  return {
    newClubRegistrationState,
    clearArrayData,
    clearObjData,
    confirmSave,
    getNewClubRegistration,
    getNewClubRegistrationWorkflow,
    putNewClubRegistrationWorkflow
  };
};

export default UseNewClubRegistrationData;