import { useEffect, useState } from 'react';

import useSecurityData from '../../../../common/state/security/UseSecurityData';

import useCommunicationsHubData from '../../../state/communicationsHub/UseCommunicationsHubData';

const UseCommunicationsHubSettings = () => {
  const { userInfo } = useSecurityData();
  const { notificationFrequncyState, personNotificationSettingState,
    getNotificationFrequencyData, getPersonNotificationSettingData, putPersonNotificationSettingData } = useCommunicationsHubData();
  const [notificationTypeState, setNotificationTypeState] = useState([]);
  const [frequencyOptions, setFrequencyOptions] = useState([]);

  const onDropChange = (frequencyId, methodId, notificationId) => {
    const notificationsArray = JSON.parse(JSON.stringify(notificationTypeState));
    const notification = notificationsArray.find(({ notificationTypeId }) => notificationTypeId === notificationId);
    const method = notification.notificationMethods.find(({ notificationMethodId }) => notificationMethodId === methodId);
    method.notificationFrequencyId = frequencyId;
    notification.notificationMethods.map(obj => methodId === obj.notificationMethodId ? method : undefined || obj);
    const newArray = notificationsArray.map(obj => notificationId === obj.notificationTypeId ? notification : undefined || obj);
    setNotificationTypeState(newArray);
  };

  const onSaveButtonClicked = () => {
    if (userInfo?.isLoggedIn === true) {
      putPersonNotificationSettingData(0, notificationTypeState);
    }
  };

  const onCancelButtonClicked = () => {
    if (personNotificationSettingState.isArrayLoaded === true) {
      setNotificationTypeState(personNotificationSettingState.arrayData);
    }
  };

  useEffect(() => {
    if (userInfo?.isLoggedIn === true) {
      if (notificationFrequncyState.isArrayLoaded !== true
        && notificationFrequncyState.isArrayLoading !== true) {
        getNotificationFrequencyData();
      } else if (notificationFrequncyState.isArrayLoaded === true) {
        const options = notificationFrequncyState.arrayData.map((frequency) => {
          return {
            id: frequency.notificationFrequencyId,
            name: frequency.description
          };
        });
        options.unshift({ id: 0, name: 'None' })
        setFrequencyOptions(options);
      }
      if (personNotificationSettingState.isArrayLoaded !== true
        && personNotificationSettingState.isArrayLoading !== true) {
        getPersonNotificationSettingData(0);
      } else if (personNotificationSettingState.isArrayLoaded === true) {
        setNotificationTypeState(personNotificationSettingState.arrayData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo?.isLoggedIn, notificationFrequncyState.isArrayLoaded, personNotificationSettingState.isArrayLoaded]);


  return {
    notificationTypeState,
    notificationTypeStateIsSaving: personNotificationSettingState.isSaving,
    notificationTypeStateIsLoading: personNotificationSettingState.isArrayLoading,
    frequencyOptions,
    frequencyOptionsIsLoading: notificationFrequncyState.isArrayLoading,
    onDropChange,
    onSaveButtonClicked,
    onCancelButtonClicked
  };

};

export default UseCommunicationsHubSettings;