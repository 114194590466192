import React, { Fragment } from 'react';

import useExpandedRow from '../UseExpandedRow';

import ProgramCategoryGridLarge from './ProgramCategoryGridLarge';
import ProgramCategoryGridSmall from './ProgramCategoryGridSmall';

const ProgramCategoryGrid = ({ state, onEdit }) => {
  const { expandedId, onClick } = useExpandedRow();

  return (
    <Fragment>
      <ProgramCategoryGridLarge
        state={state}
        onEdit={onEdit}
        expandedId={expandedId}
        onClick={onClick}
      />
      <ProgramCategoryGridSmall
        state={state}
        onEdit={onEdit}
        expandedId={expandedId}
        onClick={onClick}
      />
    </Fragment>
  )
};

export default ProgramCategoryGrid;