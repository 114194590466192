import { useContext } from 'react';

import { ProgramLevelStateContext } from './ProgramLevelContextProvider';

import ProgramLevelData from './ProgramLevelData';

import Constants from '../../utils/Constants';

const useProgramLevelData = () => {
  const [programLevelState, setProgramLevelState] = useContext(ProgramLevelStateContext);

  const getProgramLevelByProgramType = (orgUnitId, programTypeId) => {
    ProgramLevelData.getProgramLevelByProgramTypeData(orgUnitId, programTypeId, programLevelState, setProgramLevelState);
  };

  const getProgramLevelInstance = (programLevelInstanceId) => {
    ProgramLevelData.getProgramLevelInstanceData(programLevelInstanceId, programLevelState, setProgramLevelState);
  };

  const postProgramLevelInitiation = (orgUnitId, programTypeId) => {
    ProgramLevelData.postProgramLevelInitiationData(orgUnitId, programTypeId, programLevelState, setProgramLevelState);
  };

  const postProgramLevelRenewal = (programLevelInstanceId) => {
    ProgramLevelData.postProgramLevelRenewalData(programLevelInstanceId, programLevelState, setProgramLevelState);
  };

  const clearProgramLevelObjData = () => {
    setProgramLevelState({
      ...programLevelState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      programLevelInstanceId: Constants.DEFAULT_ID
    });
  };

  const clearProgramLevelArrayData = () => {
    setProgramLevelState({
      ...programLevelState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      orgUnitId: '',
      programTypeId: Constants.DEFAULT_ID
    });
  };

  const confirmSave = () => {
    setProgramLevelState({
      ...programLevelState,
      isSaved: false
    });
  };

  return {
    programLevelState,
    setProgramLevelState,
    getProgramLevelByProgramType,
    getProgramLevelInstance,
    postProgramLevelInitiation,
    postProgramLevelRenewal,
    clearProgramLevelObjData,
    clearProgramLevelArrayData,
    confirmSave
  };
};

export default useProgramLevelData;