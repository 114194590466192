const ClubRecognitionFilterValidation = async (formState) => {
  let errors = {};

  if (!formState.orgUnitId) {
    errors.orgUnitId = `${formState.orgUnitLabel} must be valid`
  }

  return errors ? errors : {};
};

export default ClubRecognitionFilterValidation;