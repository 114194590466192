import { useEffect, useState } from "react";

const useBasicKpiDetail = (kpiMeasureState) => {
  const [kpiReportFieldsState, setKpiReportFieldsState] = useState([]);

  useEffect(() => {
    if (kpiMeasureState.isArrayLoaded === true) {
      setKpiReportFieldsState(organizeKpiReportFieldsArray(kpiMeasureState.arrayData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kpiMeasureState]);


  function organizeKpiReportFieldsArray(arrayData) {
    arrayData.sort((a, b) => { return a.displayOrder - b.displayOrder; });
    const reportArray = [];

    for (const fieldObj of arrayData) {
      if (reportArray.length === 0 || fieldObj.displayOrder % 2 > 0) {
        const newArray = [fieldObj];

        reportArray.push(newArray);
      } else {
        reportArray[reportArray.length - 1].push(fieldObj);
      }
    }

    return reportArray;
  };

  return {
    kpiReportFieldsState
  };
};

export default useBasicKpiDetail;