import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import calculateTimeRecognitions from './CalculateTimeRecognitions';
import calculateTimeRelayTimeRecognitions from './CalculateTimeRelayTimeRecognitions';
import calculateTimeRecognitionsConversion from './CalculateTimeRecognitionsConversion';
import calculateTimeRecords from './CalculateTimeRecords';
import getCalculateTimeRecordsRunLog from './GetCalculateTimeRecordsRunLog';
import deleteTimeRecordsRunLog from './DeleteTimeRecordsRunLog';


const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const TimeBatchProcessData = {
  INITIAL_STATE,
  calculateTimeRecognitions,
  calculateTimeRelayTimeRecognitions,
  calculateTimeRecognitionsConversion,
  calculateTimeRecords,
  getCalculateTimeRecordsRunLog,
  deleteTimeRecordsRunLog
};

export default TimeBatchProcessData;