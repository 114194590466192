import React, { Fragment } from 'react';
import RightArrowIcon from '../../icons/RightArrowIcon';

import global from '../../GlobalStyle.module.css';

const GridRow = ({ report, onClick, formState }) => {
  if (formState?.reportCategoryName !== '' && formState?.reportCategoryName === report.rc[0].ReportCategoryName) {
    return (
      <Fragment>
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowInfo}>
            <div className='row'>
              <div className={['col-xs-6 col-sm-3 visible-sm visible-xs', global.SmallTableRowLabels].join(' ')}>
                Report Name
              </div>
              <div onClick={(e) => onClick(report.ReportId, e)} className={['col-xs-6 col-sm-3 visible-sm visible-xs', global.SmallTableRowData].join(' ')}>
                {report.ReportName}
                <button
                  className={global.IconButtonMargin}
                  type="button"
                  onClick={(e) => onClick(report.ReportId, e)}>
                  <RightArrowIcon />
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  } else if (formState?.reportCategoryName === '') {
    return (
      <Fragment>
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowInfo}>
            <div className='row'>
              <div className={['col-xs-5 col-sm-3 visible-sm visible-xs', global.SmallTableRowLabels].join(' ')}>
                Report Name
              </div>
              <div onClick={(e) => onClick(report.ReportId, e)} className={['col-xs-7 col-sm-9 visible-sm visible-xs', global.SmallTableRowData].join(' ')}>
                {report.ReportName}
                <button
                  className={global.IconButtonMargin}
                  type="button"
                  onClick={(e) => onClick(report.ReportId, e)}>
                  <RightArrowIcon />
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  } else {
    return (
      <Fragment />
    )
  }
}

const ReportGridSmall = ({ state, onClick, formState }) => (
  <Fragment>
    <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
      {state.isArrayLoaded !== true
        ? (<div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            {state.isArrayLoading === true
              ? <div className={global.SmallTableRowLabels}>Loading... </div>
              : <div className={global.SmallTableRowLabels}>No Reports</div>}
          </div>
        </div>
        ) : Array.isArray(state.arrayData) && state.arrayData.length > 0
          ? state.arrayData.map((report, i) =>
            <GridRow
              key={i}
              report={report}
              onClick={onClick}
              formState={formState} />)
          : (
            <div className={global.SmallTableRow}>
              <div className={global.SmallTableRowHead}>
                &nbsp;
              </div>
              <div className={global.SmallTableRowInfo}>
                <div className={global.SmallTableRowLabels}>No Reports</div>
              </div>
            </div>)
      }
    </div>
  </Fragment>
);

export default ReportGridSmall;