import React, { Fragment } from 'react';

import UseCommunicationsHubInbox from './UseCommunicationsHubInbox';

import InboxGrids from '../components/grids/inbox/InboxGrids';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../common/utils/Constants';

const CommunicationsHubInbox = () => {
  const {
    messageState,
    onDeleteClick,
    onReadMessage
  } = UseCommunicationsHubInbox();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Inbox - Messages</Headings.H3>
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12">
          <InboxGrids
            messageState={messageState}
            onDeleteClick={onDeleteClick}
            onReadMessage={onReadMessage} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={messageState.isArrayLoading || messageState.isObjLoading} />
    </Fragment >
  );
};

export default CommunicationsHubInbox;