import { isValidDate, isValidBirthDate } from '../../../../common/utils/validation';
import Constants from '../../../../common/utils/Constants';

const HqMemberClubTransferApprovalValidation = async (formState) => {
  let errors = {};

  if (formState.isRejected === true) {
    if (formState.stepNotes.trim() === '') {
      errors.stepNotes = "Reject reason is required";
    } else if (formState.stepNotes.length > 8000) {
      errors.stepNotes = "Reject reason cannot be more than 8000 characters long";
    }
  } else {
    if (formState.lastCompetitionDate !== null) {
      if (formState.lastCompetitionDate === Constants.BLANK_DATE_STRING) {
        errors.lastCompetitionDate = 'Last Competition Date is required';
      } else if (!isValidDate(formState.lastCompetitionDate)) {
        errors.lastCompetitionDate = 'Last Competition Date must be a valid date';
      } else if (!isValidBirthDate(formState.lastCompetitionDate)) {
        errors.lastCompetitionDate = 'Last Competition Date cannot be in the future';
      }
    }
  }

  return errors ? errors : {};
};

export default HqMemberClubTransferApprovalValidation;