import React, { Fragment } from 'react';

import LargeGrid from './LargeGrid';
import SmallGrid from './SmallGrid';

const GroupInviteGrid = ({data, isLoading, onGroupInviteClicked}) => (
  <Fragment>
    <LargeGrid data={data} isLoading={isLoading} onGroupInviteClicked={onGroupInviteClicked}/>
    <SmallGrid data={data} isLoading={isLoading} onGroupInviteClicked={onGroupInviteClicked}/>
  </Fragment>
);

export default GroupInviteGrid;