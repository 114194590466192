const SafeSportCategoryMeasureApprovalsValidation = async (formState) => {
  let errors = {};

  if (formState.score === '' || parseInt(formState.score) < 0) {
    errors.score = `Score must be a positive number`;
  } else if (parseInt(formState.score) > formState.maxValue) {
    errors.score = `Score cannot exceed the maximum point value`;
  }

  if (formState.notes.trim().length > 8000) {
    errors.notes = 'Notes cannot exceed 8000 characters';
  }

  return errors ? errors : {};
};

export default SafeSportCategoryMeasureApprovalsValidation;