import React from 'react';

import useMemberClubTransfer from './UseMemberClubTransfer';

import ClubTransferGrid from '../components/grids/clubTransferGrid/ClubTransferGrid';
import WorkFlowNavigation from '../components/navigation/WorkFlowNavigation';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

const MemberClubTransfer = () => {
  const {
    Constants,
    personClubTransferState,
    onRequestClicked
  } = useMemberClubTransfer();

  return (
    <div className="row">
      <WorkFlowNavigation headerTitle={"Club Transfer Request Queue"}>
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <ClubTransferGrid
              data={personClubTransferState.arrayData}
              isLoading={personClubTransferState.isArrayLoading}
              onRequestClicked={onRequestClicked} />
          </div>
        </div>
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.LOADING_MSG}
          displayPopUp={personClubTransferState.isArrayLoading} />
      </WorkFlowNavigation>
    </div>
  );
};

export default MemberClubTransfer;