import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Navigate } from '../../../common/wrappers/ReactRouterDom';

import Reporting from './reporting/Reporting';
import MyReports from './myReports/MyReports';

import LoadingModal from '../../../common/components/dialogs/LoadingModal';
import SecuredRouteElement from '../../../common/components/security/SecuredRouteElement';

import useNavRoutes from '../../../common/state/security/UseNavRoutes';

const ReportRoutes = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return (
    <Routes>
      <Route path={navRoutes.REPORTING_HOME?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.REPORTING_HOME}
            editElement={<Reporting />}
            readOnlyElement={<MyReports />} />
        }
      />
      <Route path={'/'} element={<Navigate to={navRoutes.REPORTING_HOME?.path} replace />} />
    </Routes>
  );
};

const Reports = () => (
  <ReportRoutes />
);

export default Reports;