import { BASIC_INITIAL_STATE } from '../../../../common/utils/HttpHelper';

import getGroupNationalTeamInvitesData from './GetGroupNationalTeamInvitesData';
import getGroupNationalTeamInviteWorkflowData from '../GetWorkFlowByIdData';
import putGroupNationalTeamInviteWorkflowData from '../PutWorkFlowData';

const GroupNationalTeamInviteData = {
  INITIAL_STATE: {...BASIC_INITIAL_STATE},
  getGroupNationalTeamInvitesData,
  getGroupNationalTeamInviteWorkflowData,
  putGroupNationalTeamInviteWorkflowData
};

export default GroupNationalTeamInviteData;