import { useEffect } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import { navLinks } from '../../../UseNavLinks';

import useGroupNationalTeamInviteData from '../../../state/workflow/groupNationalTeamInvite/UseGroupNationalTeamInviteData';

import Constants from '../../../../common/utils/Constants';

const useGroupNationalTeamInvite = () => {
  const navigate = useNavigate();
  const { groupNationalTeamInviteState, getGroupNationalTeamInvites } = useGroupNationalTeamInviteData();

  const onGroupInviteClicked = (e, groupInvite) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(navLinks.GROUP_NATIONAL_TEAM_INVITE_APPROVALS, { state: { groupInvite } });
  };

  useEffect(() => {
    if (groupNationalTeamInviteState.isArrayLoaded !== true) {
      getGroupNationalTeamInvites();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    Constants,
    groupNationalTeamInviteState,
    onGroupInviteClicked
  };
};

export default useGroupNationalTeamInvite;