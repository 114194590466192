import React, { createContext, useState } from 'react';
import { DEFAULT_ID } from '../../../../../common/utils/Constants';

export const GroupScholasticAllAmericanFiltersStateContext = createContext();

const INITIAL_STATE = {
  zoneOrgUnitCode: DEFAULT_ID,
  zoneOrgUnitName: '--',
  competitionGenderTypeCode: DEFAULT_ID,
  competitionGenderTypeName: '--',
};

const GroupScholasticAllAmericanFiltersContextProvider = ({ children }) => {
  const stateHook = useState(INITIAL_STATE);

  return (
    <GroupScholasticAllAmericanFiltersStateContext.Provider value={stateHook}>
      {children}
    </GroupScholasticAllAmericanFiltersStateContext.Provider>
  );
};

export default GroupScholasticAllAmericanFiltersContextProvider;