/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';

import SGColumnHeader from '../../../../../../common/components/grids/SGColumnHeader';

import useSortableGrid from '../../../../../../common/utils/UseSortableGrid';
import SGConstants from '../../../../../../common/utils/SGConstants';
import { formatDate } from '../../../../../../common/utils/DateFunctions';

import global from '../../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ clubReg, onRegistrationClicked }) => (
  <tr>
    <td><a onClick={e => onRegistrationClicked(e, clubReg)}>{`${clubReg.lscCode || ''} - ${clubReg.clubName || ''}/${clubReg.clubCode || ''}`}</a></td>
    <td>{formatDate(clubReg.submissionDate)}</td>
    <td>{clubReg.offeringName}</td>
  </tr>
);

const LargeGrid = ({ data, isLoading, onRegistrationClicked }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(data);

  return (
    <Fragment>
      <table className={[global.UsasTable, 'visible-lg visible-md'].join(' ')}>
        <thead>
          <tr>
            <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'LSC - Club Name/Club Code'} columnField={'lscCode'}
              sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
            <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Submission Date'} columnField={'submissionDate'}
              sortType={SGConstants.SORT_TYPE_DATE} onColumnClicked={onColumnClicked} />
            <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Club Registration Type'} columnField={'offeringName'}
              sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          </tr>
        </thead>
        <tbody>
          {isLoading === true
            ? <tr><td colSpan="3">Loading...</td></tr>
            : sortableGridState.sortedGridData.length > 0
              ? sortableGridState.sortedGridData.map((clubReg, i) => <GridRow key={i} clubReg={clubReg} onRegistrationClicked={onRegistrationClicked} />)
              : <tr><td colSpan="3">No Club Registrations</td></tr>
          }
        </tbody>
      </table>
    </Fragment>
  );
};
export default LargeGrid;