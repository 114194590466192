import { useEffect } from "react";

import useKpiMeasureData from "../../../../state/kpiMeasure/UseKpiMeasureData";

import useEnvironmentVariableData from "../../../../../common/state/environmentVariable/UseEnvironmentVariableData";

const useKpiMeasureDetail = (categoryMeasure, programLevelInstanceId) => {
  const { KPI_BASIC_MEASURE_TYPE_ID, KPI_COACH_ROSTER_MEASURE_TYPE_ID, KPI_COACH_YEARS_MEASURE_TYPE_ID
  } = useEnvironmentVariableData();
  const { kpiMeasureState, getKpiMeasure } = useKpiMeasureData();

  useEffect(() => {
    if (categoryMeasure && kpiMeasureState.isArrayLoaded === false) {
      if (programLevelInstanceId > 0 && categoryMeasure.categoryMeasureId > 0
        && categoryMeasure.endpointUrl) {
        getKpiMeasure(programLevelInstanceId, categoryMeasure.categoryMeasureId, categoryMeasure.endpointUrl);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryMeasure, kpiMeasureState.isArrayLoaded]);

  return {
    kpiMeasureState,
    KPI_BASIC_MEASURE_TYPE_ID,
    KPI_COACH_ROSTER_MEASURE_TYPE_ID,
    KPI_COACH_YEARS_MEASURE_TYPE_ID
  };
};

export default useKpiMeasureDetail;