import { Fragment } from "react";

import useReporting from './UseReporting';

import PowerBiReportGrid from "../../../../common/components/grids/powerBiReport/PowerBiReportGrid";
import Dropdown from "../../../../common/components/dropdowns/Dropdown";

const Reporting = () => {
  const {
    powerBiReportState,
    onClick,
    showModal,
    hideModal,
    formState,
    errorState,
    onValueTextPairChanged,
    reportCategoryFilterState
  } = useReporting();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12 col-sm-4 pull-right usas-extra-bottom-margin">
          <Dropdown label={"Report Category Name Filter"}
            name={"reportCategoryId"}
            value={formState.reportCategoryId}
            options={reportCategoryFilterState}
            error={errorState.reportCategoryId}
            message={errorState.reportCategoryId}
            isLoading={false}
            areValuesIntegers={true}
            onChange={(newValue, newName, e) => e && e.target && e.target.value &&
              onValueTextPairChanged(newValue, 'reportCategoryId', newName, 'reportCategoryName')} /></div>
      </div>
      <PowerBiReportGrid
        state={powerBiReportState}
        onClick={onClick}
        showModal={showModal}
        hideModal={hideModal}
        formState={formState}
      />
    </Fragment>
  );
};

export default Reporting;

