import React, { createContext, useState } from 'react';

export const HqMemberClubTransferFilterStateContext = createContext();

const INITIAL_STATE = {
  orgUnitId: '',
  orgUnitName: ''
};

const HqMemberClubTransferFilterContextProvider = ({ children }) => {
  const stateHook = useState(INITIAL_STATE);

  return (
    <HqMemberClubTransferFilterStateContext.Provider value={stateHook}>
      {children}
    </HqMemberClubTransferFilterStateContext.Provider>
  );
};

export default HqMemberClubTransferFilterContextProvider;