import useWorkflowStepNotesPopup from "./UseWorkflowStepNotesPopup";

import PrimaryButton from "../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../common/components/buttons/SecondaryButton";
import PopUpModal from "../../../../../common/components/dialogs/PopUpModal";
import Textarea from "../../../../../common/components/inputs/Textarea";

import global from '../../../../../common/components/GlobalStyle.module.css';

const WorkflowStepNotesPopup = ({ title, submitButtonText, notesLabel = 'Notes', onSubmitFormCallback,
  onModalCancelClicked }) => {
  const {
    formState,
    errorState,
    updateFormState,
    handleSubmit
  } = useWorkflowStepNotesPopup(onSubmitFormCallback);

  return (
    <PopUpModal
      title={title}
      widthPct={500}
      maxWidth={800}
      onModalCanceled={onModalCancelClicked}
      displayPopUp={true}>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-xs-12">
            <Textarea
              className={global.Textarea}
              label={notesLabel}
              name="stepNotes"
              value={formState.stepNotes}
              error={errorState.stepNotes}
              message={errorState.stepNotes}
              onChange={(value) => { updateFormState('stepNotes', value); }} />
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <PrimaryButton type="button" onClick={handleSubmit}>{submitButtonText}</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onModalCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </form>
    </PopUpModal>
  );
};

export default WorkflowStepNotesPopup;