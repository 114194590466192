import React from 'react';

import useHqMemberClubTransfer from './UseHqMemberClubTransfer';

import ClubTransferGrid from '../components/grids/clubTransferGrid/ClubTransferGrid';

import WorkFlowNavigation from '../components/navigation/WorkFlowNavigation';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import LscDropdown from '../../../../common/components/dropdowns/lscDropdown/LscDropdown';

const HqMemberClubTransfer = () => {
  const {
    Constants,
    personClubTransferState,
    dropdownState,
    filteredGridDataState,
    onRequestClicked,
    onDropdownValueChange
  } = useHqMemberClubTransfer();

  return (
    <div className="row">
      <WorkFlowNavigation headerTitle={"HQ Club Transfer Request Queue"}>
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12 col-md-4">
            <LscDropdown
              label={'Filter By LSC(Being Transfer To)'}
              name={'orgUnitId'}
              value={dropdownState.orgUnitId}
              onChange={(value, valueLabel, e) => e && e.target && e.target.value && onDropdownValueChange(value, valueLabel)} />
          </div>
          <div className="col-xs-12">
            <ClubTransferGrid
              data={dropdownState.orgUnitId ? filteredGridDataState : personClubTransferState.arrayData}
              isLoading={personClubTransferState.isArrayLoading}
              onRequestClicked={onRequestClicked} />
          </div>
        </div>
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.LOADING_MSG}
          displayPopUp={personClubTransferState.isArrayLoading} />
      </WorkFlowNavigation>
    </div>
  );
};

export default HqMemberClubTransfer;