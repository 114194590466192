import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "../../../../common/wrappers/ReactRouterDom";

import { navLinks } from '../../../UseNavLinks';
import { createNewWorkflowObj } from '../utils/WorkflowsUtils';

import UsePreCompetitiveRegistrationData from "../../../state/workflow/preCompetitiveRegistration/UsePreCompetitiveRegistrationData";

import Constants from '../../../../common/utils/Constants';

const usePreCompetitiveRegistrationApproval = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { preCompetitiveRegistrationState, getPreCompetitiveRegistrationWorkflow, putPreCompetitiveRegistrationWorkflow
  } = UsePreCompetitiveRegistrationData();
  const [preCompRegObjState, setPreCompRegObjState] = useState({});

  const onApprovedClicked = () => {
    const workflowObjCopy = JSON.parse(JSON.stringify(preCompetitiveRegistrationState.objData));
    putPreCompetitiveRegistrationWorkflow(preCompRegObjState.workflowId,
      createNewWorkflowObj(workflowObjCopy, preCompRegObjState.workflowStepId, Constants.WORKFLOW_STATUS_APPROVED,
        '', 0));
  };

  const onRejectClicked = () => {
    const workflowObjCopy = JSON.parse(JSON.stringify(preCompetitiveRegistrationState.objData));

    putPreCompetitiveRegistrationWorkflow(preCompRegObjState.workflowId,
      createNewWorkflowObj(workflowObjCopy, preCompRegObjState.workflowStepId, Constants.WORKFLOW_STATUS_REJECTED,
        '', 0));
  };

  const onBackClicked = () => {
    navigate(navLinks.PRE_COMP_REGISTRATION);
  };

  useEffect(() => {
    if (location.state?.preCompReg?.workflowId > 0) {
      setPreCompRegObjState(location.state.preCompReg);
      getPreCompetitiveRegistrationWorkflow(location.state.preCompReg.workflowId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (preCompetitiveRegistrationState.isSaved === true) {
      navigate(navLinks.PRE_COMP_REGISTRATION);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preCompetitiveRegistrationState.isSaved]);

  return {
    Constants,
    isSaving: preCompetitiveRegistrationState.isSaving,
    isLoading: preCompetitiveRegistrationState.isObjLoading,
    preCompetitiveRegistrationState,
    preCompRegObjState,
    onApprovedClicked,
    onRejectClicked,
    onBackClicked
  };
};

export default usePreCompetitiveRegistrationApproval;