import React, { createContext, useState } from 'react';

import ProgramLevelData from './ProgramLevelData';

export const ProgramLevelStateContext = createContext();

const ProgramLevelContextProvider = ({ children }) => {
  const stateHook = useState(ProgramLevelData.INITIAL_STATE);

  return (
    <ProgramLevelStateContext.Provider value={stateHook}>
      {children}
    </ProgramLevelStateContext.Provider>
  );
};

export default ProgramLevelContextProvider;