import React, { Fragment } from 'react';

import EditIcon from '../../../../../../common/components/icons/EditIcon';

import { formatDate } from '../../../../../../common/utils/DateFunctions';

import global from '../../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ preCompReg, onRegistrationClicked }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}>
      <span className='hidden-xs'>{preCompReg.locationName}</span>&nbsp;
      <button
        className={global.IconButton}
        type="button"
        onClick={e => onRegistrationClicked(e, preCompReg)}>
        <EditIcon />
      </button>
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Location Name</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>{preCompReg.locationName}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Submission Date</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{formatDate(preCompReg.submissionDate)}</div>
        {/*TODO add back when pre comp address logic is figured out 
      <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Address</div>
      <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{preCompReg.address}</div> */}
      </div>
    </div>
  </div>
);

const SmallGrid = ({ data, isLoading, onRegistrationClicked }) => (
  <Fragment>
    <div className={[global.SmallTable, 'hidden-lg hidden-md'].join(' ')}>
      {isLoading === true
        ? (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}><div className={global.SmallTableRowLabels}>Loading...</div></div>
          </div>
        )
        : data.length > 0
          ? data.map((preCompReg, i) => <GridRow key={i} preCompReg={preCompReg} onRegistrationClicked={onRegistrationClicked} />)
          : (
            <div className={global.SmallTableRow}>
              <div className={global.SmallTableRowHead}>&nbsp;</div>
              <div className={global.SmallTableRowInfo}><div className={global.SmallTableRowLabels}>No Pre-Competitive Registrations</div></div>
            </div>
          )
      }
    </div>
  </Fragment>
);

export default SmallGrid;