import React, { Fragment } from 'react';

import Headings from '../../../../common/components/headings/Headings';
import Dropdown from '../../../../common/components/dropdowns/Dropdown';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../common/utils/Constants';

import UseCommunicationsHubSettings from './UseCommunicationsHubSettings';

import style from './CommunicationsHubSettings.module.css';

const MessagePreferenceDropdown = ({ method, frequencyOptions, frequencyOptionsIsLoading, notificationId, onDropChange }) => (
  <Fragment>
    <div className="col-xs-12 col-sm-6 usas-extra-bottom-margin">
      <Dropdown
        label={`${method.description} Frequency`}
        options={frequencyOptions}
        name={`${method.description}Frequency`}
        value={method.notificationFrequencyId ?? 0}
        onChange={(newValue, _, e) => { e && e.target && e.target.value && onDropChange(newValue, method.notificationMethodId, notificationId) }}
        isLoading={frequencyOptionsIsLoading}
      />
    </div>
  </Fragment>
);

const MessagePreferenceSection = ({ notification, frequencyOptions, frequencyOptionsIsLoading, onDropChange }) => (
  <Fragment>
    <div className={["container", style.MessagePreferenceSection].join(' ')}>
      <div className='row usas-extra-top-margin'>
        <div className='col-xs-12'>
          <Headings.H6>{notification.notificationTypeName}</Headings.H6>
        </div>
      </div>
      {notification.notificationMethods.map((method, i) =>
        <MessagePreferenceDropdown
          key={i}
          method={method}
          notificationId={notification.notificationTypeId}
          onDropChange={onDropChange}
          frequencyOptionsIsLoading={frequencyOptionsIsLoading}
          frequencyOptions={frequencyOptions} />
      )}
    </div>
  </Fragment >
);

const CommunicationsHubSettings = () => {
  const {
    notificationTypeState,
    notificationTypeStateIsLoading,
    notificationTypeStateIsSaving,
    frequencyOptions,
    frequencyOptionsIsLoading,
    onDropChange,
    onSaveButtonClicked,
    onCancelButtonClicked
  } = UseCommunicationsHubSettings();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Settings - Notification Preferences</Headings.H3>
        </div>
      </div>
      <form noValidate className='usas-extra-bottom-margin'>
        {notificationTypeState.map((notification, i) =>
          <MessagePreferenceSection
            key={i}
            notification={notification}
            frequencyOptions={frequencyOptions}
            frequencyOptionsIsLoading={frequencyOptionsIsLoading}
            onDropChange={onDropChange} />
        )}
        {notificationTypeState.length > 0 &&
          <div className="row usas-extra-top-margin usas-extra-bottom-margin">
            <div className="col-xs-12 usas-extra-top-margin">
              <PrimaryButton type="button" onClick={onSaveButtonClicked}>Save</PrimaryButton>&nbsp;
              <SecondaryButton type="button" onClick={onCancelButtonClicked}>Cancel</SecondaryButton>
            </div>
          </div>}
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={notificationTypeStateIsSaving} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={notificationTypeStateIsLoading} />
    </Fragment>
  );
};

export default CommunicationsHubSettings;