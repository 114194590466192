import React, { Fragment } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Outlet } from '../common/wrappers/ReactRouterDom';

import CommunicationsHub from './views/communicationsHub/CommunicationsHub';
import WorkFlows from './views/workFlows/WorkFlows';
import TimeBatchProcess from './views/timeBatchProcess/TimeBatchProcess';
import Reports from './views/reporting/Reports';

import PageNotFound from '../common/components/PageNotFound';
import UnexpectedError from '../common/components/UnexpectedError';
import LoadingModal from '../common/components/dialogs/LoadingModal';
import SecuredRouteElement from '../common/components/security/SecuredRouteElement';

import useNavRoutes from '../common/state/security/UseNavRoutes';
import ErrorBoundaryFallback from '../common/components/errors/ErrorBoundaryFallback';
import ErrorBoundary from '../common/components/errors/ErrorBoundary';

const AppRoutes = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return (
    <ErrorBoundary fallback={<ErrorBoundaryFallback />}>
      <Routes>
        <Route path={navRoutes.HOME?.path} element={<Fragment><Outlet /></Fragment>}>
          <Route path={navRoutes.WORKFLOWS_ROOT?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes.WORKFLOWS_ROOT}
                editElement={<WorkFlows />} />
            }
          />
          <Route path={navRoutes.COMMUNICATIONS_HUB_ROOT?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes.COMMUNICATIONS_HUB_ROOT}
                editElement={<CommunicationsHub />} />
            }
          />
          <Route path={navRoutes.BATCH_PROCESS_ROOT?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes.BATCH_PROCESS_ROOT}
                editElement={<TimeBatchProcess />} />
            }
          />
          <Route path={navRoutes.REPORTING_ROOT?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes.REPORTING_ROOT}
                editElement={<Reports />} />
            }
          />

          <Route path="500" element={<UnexpectedError />} />
          <Route path="/*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </ErrorBoundary>
  );
};

export default AppRoutes;