import { useState } from 'react';

import SafeSportData from './SafeSportData';

const useSafeSportData = () => {
  const [safeSportState, setSafeSportState] = useState(SafeSportData.INITIAL_STATE);

  const getSafeSportRequest = () => {
    SafeSportData.getSafeSportRequestData(safeSportState, setSafeSportState);
  };

  const getSafeSportWorkflow = (workflowId) => {
    SafeSportData.getSafeSportWorkflowData(workflowId, safeSportState, setSafeSportState);
  };

  const putSafeSportWorkflow = (workflowId, workflowObj) => {
    SafeSportData.putSafeSportWorkflowData(workflowId, workflowObj, safeSportState, setSafeSportState);
  };

  const clearSafeSportObjData = () => {
    setSafeSportState({
      ...safeSportState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {}
    });
  };

  const clearSafeSportArrayData = () => {
    setSafeSportState({
      ...safeSportState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: []
    });
  };

  const confirmSafeSportSave = () => {
    setSafeSportState({
      ...safeSportState,
      isSaved: false
    });
  };

  return {
    safeSportState,
    setSafeSportState,
    getSafeSportRequest,
    getSafeSportWorkflow,
    putSafeSportWorkflow,
    clearSafeSportArrayData,
    clearSafeSportObjData,
    confirmSafeSportSave
  };
};

export default useSafeSportData;