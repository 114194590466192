import { BASIC_INITIAL_STATE } from '../../../../common/utils/HttpHelper';

import getClubRecognitionRequestData from './GetClubRecognitionRequestData';
import getClubRecognitionWorkflowData from '../GetWorkFlowByIdData';
import putClubRecognitionWorkflowData from '../PutWorkFlowData';

const ClubRecognitionData = {
  INITIAL_STATE: {...BASIC_INITIAL_STATE},
  getClubRecognitionRequestData,
  getClubRecognitionWorkflowData,
  putClubRecognitionWorkflowData
};

export default ClubRecognitionData;