import React, { Fragment } from 'react';

import ShowIcon from '../../../../../../common/components/icons/ShowIcon';
import HideIcon from '../../../../../../common/components/icons/HideIcon';
import DeleteIcon from '../../../../../../common/components/icons/DeleteIcon';

import { formatDate } from '../../../../../../common/utils/DateFunctions';

import localConstants from '../../../../../Constants';

import global from '../../../../../../common/components/GlobalStyle.module.css';

import style from '../Grid.module.css';

const DetailTableRow = ({ message }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableDetailRowHead}>
      <span>Message</span>&nbsp;
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-12', global.SmallTableRowData].join(' ')}>{message}</div>
      </div>
    </div>
  </div>
);

const DetailTable = ({ message }) => (
  <div className={global.SmallTableDetailTable}>
    <DetailTableRow message={message} />
  </div>
);

const GridRow = ({ message, onClick, expandedId, onDeleteClick, onReadMessage }) => (
  <Fragment>
    <div className={global.SmallTableRow}>
      <div className={global.SmallTableRowHead}>
        &nbsp;
        <button
          className={global.IconButton}
          type="button"
          onClick={e => { onClick(e, message.id); onReadMessage(message); }}>
          {expandedId === message.id
            ? <HideIcon />
            : <ShowIcon />}
        </button>
        <button
          className={global.IconButtonMobileMargin}
          type="button"
          onClick={e => onDeleteClick(message)}>
          <DeleteIcon />
        </button>
      </div>
      <div className={['row', global.SmallTableRowInfo, message.status !== localConstants.MESSAGE_READ_STATUS_TEXT ? global.Bold : style.ReadBackground].join(' ')}>
        <div className={['col-xs-4 col-sm-2', global.SmallTableRowLabels].join(' ')}>Subject</div>
        <div className={['col-xs-8 col-sm-4', global.SmallTableRowData].join(' ')}>{message.subject}</div>
        <div className={['col-xs-4 col-sm-2', global.SmallTableRowLabels].join(' ')}>Date</div>
        <div className={['col-xs-8 col-sm-4', global.SmallTableRowData].join(' ')}>{formatDate(message.dateSent)}</div>
        <div className={['col-xs-4 col-sm-2', global.SmallTableRowLabels].join(' ')}>Type</div>
        <div className={['col-xs-8 col-sm-4', global.SmallTableRowData].join(' ')}>{message.notificationTypeName}</div>
      </div>
    </div>
    {expandedId === message.id && <DetailTable message={message.message} />}
  </Fragment>
);

const SmallGrid = ({ messageState, onClick, expandedId, onDeleteClick, onReadMessage }) => (
  <Fragment>
    <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
      {messageState.isArrayLoading === true
        ? (
          <div className={global.TableRow}>
            <div className={global.TableRowHead}>&nbsp;</div>
            <div className={[global.TableRowInfo, global.Bold].join(' ')}>&nbsp;&nbsp;Loading...</div>
          </div>
        )
        : messageState.arrayData.length > 0
          ? messageState.arrayData.map((message, i) =>
            <GridRow
              key={i}
              message={message}
              expandedId={expandedId}
              onClick={onClick}
              onDeleteClick={onDeleteClick}
              onReadMessage={onReadMessage} />)
          : (
            <div className={global.TableRow}>
              <div className={global.TableRowHead}>&nbsp;</div>
              <div className={[global.TableRowInfo, global.Bold].join(' ')}>&nbsp;&nbsp;No Messages</div>
            </div>
          )
      }
    </div>
  </Fragment>
);

export default SmallGrid;