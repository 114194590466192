/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';
import RightArrowIcon from '../../icons/RightArrowIcon'; 

import global from '../../GlobalStyle.module.css';

const TableRow = ({ report, onClick, formState }) => {

  if (formState?.reportCategoryName !== '' && formState?.reportCategoryName === report.rc[0].ReportCategoryName) {
    return (
      <Fragment>
        <tr>
          {/* TODO: onClick to open new page on click */}
          <td>{report.ReportName}</td>
          <td>
            {/* TODO: get correct icon for new tab */}
            <button
              className={global.IconButtonMargin}
              type="button"
              onClick={(e) => onClick(report.ReportId, e)}>
              <RightArrowIcon />
            </button>
          </td>
        </tr>
      </Fragment>
    );
  } else if (formState?.reportCategoryName === '') {
    return (
      <Fragment>
        <tr>
          {/* TODO: onClick to open new page on click */}
          <td>{report.ReportName}</td>
          <td>
            {/* TODO: get correct icon for new tab */}
            <button
              className={global.IconButtonMargin}
              type="button"
              onClick={(e) => onClick(report.ReportId, e)}>
              <RightArrowIcon />
            </button>
          </td>
        </tr>
      </Fragment>
    );
  } else {
    return (
      <Fragment />
    )
  }
};

const ReportGridLarge = ({ state, onClick, formState }) => (
  <Fragment>
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <th>Report Name</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {state.isArrayLoaded !== true
          ? (<tr>
            {state.isArrayLoading === true
              ? <td colSpan="4">Loading...</td>
              : <td colSpan="4">No Reports</td>}
          </tr>)
          : Array.isArray(state.arrayData) && state.arrayData.length > 0
            ? state.arrayData.map((report, i) =>
              <TableRow
                key={i}
                report={report}
                onClick={onClick}
                formState={formState} />)
            : (<tr>
              <td colSpan="4">No Reports</td>
            </tr>)}
      </tbody>
    </table>
  </Fragment>
);

export default ReportGridLarge;