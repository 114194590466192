import React from 'react';

import useRow from './useRow';

import LinkButton from '../../../../common/components/buttons/LinkButton';

import global from '../../../../common/components/GlobalStyle.module.css';
import style from './ApprovalQueues.module.css';

const NoQueues = () => {
  return (
    <div className={global.SmallTableRow}>
      <div className={global.SmallTableRowHead}>
        &nbsp;
      </div>
      <div className={global.SmallTableRowInfo}>
        <div className={global.SmallTableRowLabels}>No approval queues are accessible right now.</div>
      </div>
    </div>
  );
};

const GridRow = ({ queue }) => {
  const { onClick } = useRow(queue);

  return (
    <div className={global.SmallTableRow}>
      <div className={global.SmallTableRowHead}><LinkButton className={style.QueueLink} action={onClick}>{queue.workflowType}</LinkButton></div>
      <div className={global.SmallTableRowInfo}>
        <div className='row'>
          <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>New Requests:</div>
          <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{queue.pendingCount}</div>
          <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Requires Revisit:</div>
          <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{queue.revisitCount}</div>
        </div>
      </div>
    </div>
  );
};

const SmallGrid = ({ approvalQueues }) => {
  const noQueuesAvailable = Array.isArray(approvalQueues) === false || approvalQueues?.length === 0;

  return (
    <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
      {noQueuesAvailable === true && <NoQueues />}
      {noQueuesAvailable === false && approvalQueues.map((queue, i) => (<GridRow key={i} queue={queue} />))}
    </div>
  );
};

export default SmallGrid;