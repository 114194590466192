import useNavRoutes from "../../../../common/state/security/UseNavRoutes";
import { useNavigate } from "../../../../common/wrappers/ReactRouterDom";

const useRow = (queue) => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();

  const onClick = (e) => {
    e?.preventDefault();

    if (navRoutes[queue.routeName]?.route) {
      navigate(navRoutes[queue.routeName].route);
    }
  };

  return {
    onClick
  };
};

export default useRow;