import { BASIC_INITIAL_STATE } from '../../../../common/utils/HttpHelper';

import getExistingClubRegistration from './GetExistingClubRegistrationData';
import getExistingClubRegistrationWorkflow from '../GetWorkFlowByIdData';
import putExistingClubRegistrationWorkflow from '../PutWorkFlowData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const ExistingClubRegistrationData = {
  INITIAL_STATE,
  getExistingClubRegistration,
  getExistingClubRegistrationWorkflow,
  putExistingClubRegistrationWorkflow
};

export default ExistingClubRegistrationData;