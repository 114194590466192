import React, { Fragment } from 'react';

import LargeGrid from './LargeGrid';
import SmallGrid from './SmallGrid';

const ClubTransferGrid = ({data, isLoading, onRequestClicked}) => (
  <Fragment>
    <LargeGrid data={data} isLoading={isLoading} onRequestClicked={onRequestClicked}/>
    <SmallGrid data={data} isLoading={isLoading} onRequestClicked={onRequestClicked}/>
  </Fragment>
);

export default ClubTransferGrid;