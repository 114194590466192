import { BASIC_INITIAL_STATE } from '../../../../common/utils/HttpHelper';

import getGroupScholasticAllAmericanInvitesData from './GetGroupScholasticAllAmericanInvitesData';
import getGroupScholasticAllAmericanInviteWorkflowData from '../GetWorkFlowByIdData';
import putGroupScholasticAllAmericanInviteWorkflowData from '../PutWorkFlowData';

const GroupScholasticAllAmericanInviteData = {
  INITIAL_STATE: {...BASIC_INITIAL_STATE},
  getGroupScholasticAllAmericanInvitesData,
  getGroupScholasticAllAmericanInviteWorkflowData,
  putGroupScholasticAllAmericanInviteWorkflowData
};

export default GroupScholasticAllAmericanInviteData;