import { TimesHttpHelper, NO_DATA_MESSAGE } from "../../../common/utils/HttpHelper";

const calculateTimeRecognitionsConversion = (state, setState) => {
  if (state.isObjLoading !== true && !state.message) {
    let newState = {
      ...state,
      isObjLoading: true,
      isObjLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const url = `/TimeCalculation/CalculateTimeRecognitionsConversion`;
    TimesHttpHelper(url, 'GET')
      .then((objData) => {
        //This does not return a proper response, thus does not update state
        if (!objData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          setState({
            ...newState,
            isObjLoading: false,
            isObjLoaded: true,
            objData,
            message: ''
          });
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isObjLoaded: false,
          isObjLoading: false,
          message: message
        });
      });
  };
};

export default calculateTimeRecognitionsConversion;