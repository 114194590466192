export const navLinks = {
  ROOT: '/workFlows',
  NOTIFICATION_CENTER: '/workflows/notificationcenter',
  LSC_NEW_CLUB_REGISTRATION: '/workflows/lscnewclubregistration',
  LSC_NEW_CLUB_REGISTRATION_APPROVALS: '/workflows/lscnewclubregistration/approvals',
  HQ_NEW_CLUB_REGISTRATION: '/workflows/hqnewclubregistration',
  HQ_NEW_CLUB_REGISTRATION_APPROVALS: '/workflows/hqnewclubregistration/approvals',
  CLUB_TRANSFER_REQUEST: '/workflows/clubtransfer',
  CLUB_TRANSFER_REQUEST_APPROVALS: '/workflows/clubtransfer/approvals',
  HQ_CLUB_TRANSFER_REQUEST: '/workflows/hqmemberclubtransfers',
  HQ_CLUB_TRANSFER_REQUEST_APPROVALS: '/workflows/hqmemberclubtransfers/approvals',
  EXISTING_CLUB_REGISTRATION: '/workflows/existingclubregistration',
  EXISTING_CLUB_REGISTRATION_APPROVALS: '/workflows/existingclubregistration/approvals',
  PRE_COMP_REGISTRATION: '/workflows/precompetitiveregistration',
  PRE_COMP_REGISTRATION_APPROVALS: '/workflows/precompetitiveregistration/approvals',
  MEET_SANCTION_REQUEST: '/workflows/meetsanction',
  MEET_SANCTION_REQUEST_APPROVALS: '/workflows/meetsanction/approvals',
  SAFE_SPORT_REQUEST: '/workflows/safesport',
  SAFE_SPORT_PROGRAM_LEVEL: '/workflows/safesport/programlevel',
  SAFE_SPORT_PROGRAM_LEVEL_APPROVALS: '/workflows/safesport/programlevel/approvals',
  SAFE_SPORT_TASK_APPROVALS: '/workflows/safesport/task/approvals',
  SAFE_SPORT_TASK_REQUESTS: '/workflows/safesport/task/requests',
  CLUB_RECOGNITION_REQUEST: '/workflows/clubrecognition',
  CLUB_RECOGNITION_PROGRAM_LEVEL: '/workflows/clubrecognition/programlevel',
  CLUB_RECOGNITION_PROGRAM_LEVEL_APPROVALS: '/workflows/clubrecognition/programlevel/approvals',
  CLUB_RECOGNITION_TASK_APPROVALS: '/workflows/clubrecognition/task/approvals',
  CLUB_RECOGNITION_TASK_REQUESTS: '/workflows/clubrecognition/task/requests',
  GROUP_SCHOLASTIC_ALL_AMERICA_INVITE: '/workflows/scholasticallamerica',
  GROUP_SCHOLASTIC_ALL_AMERICA_INVITE_APPROVALS: '/workflows/scholasticallamerica/approvals',
  GROUP_NATIONAL_TEAM_INVITE: '/workflows/nationalteamregistration',
  GROUP_NATIONAL_TEAM_INVITE_APPROVALS: '/workflows/nationalteamregistration/approvals',
  LEARN_TO_SWIM_REGISTRATION: '/workflows/learntoswimregistration',
  LEARN_TO_SWIM_REGISTRATION_APPROVALS: '/workflows/learntoswimregistration/approvals'
};