import { useEffect } from 'react';

import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';

import useTimeBatchProcessData from '../../../state/timeBatchProcess/UseTimeBatchProcessData';

import validate from './TimeBatchProcessTimeRecognitionsValidation';

const UseTimeBatchProcessTimeRecognitions = () => {
  const { 
    timeBatchProcessRunLogState,
    calculateTimeRecognitions,
    getCalculateTimeRecordsRunLog,
    deleteTimeRecordsRunLog } = useTimeBatchProcessData();
  const { formState, errorState, setIsDirty, onFormValueChanged, handleSubmit, setFormState } = useForm(initialFormState, submitFormCallback, validate);

  function submitFormCallback(formState) {
    const processObject = createTimeBatchProcessObject(formState);
    calculateTimeRecognitions(processObject);
  };
  
  const onRunProcess = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setIsDirty(true);
    handleSubmit();
  };

  function createTimeBatchProcessObject(formState) {
    return {
      meetId: formState.meetId || undefined,
      personId: formState.personId || undefined,
      startDate: formState.startDate !== Constants.BLANK_DATE_STRING ? formState.startDate : undefined,
      endDate:  formState.endDate !== Constants.BLANKend_DATE_STRING ? formState.endDate : undefined
    };
  };

  const onCancel = () => {
    setFormState(initialFormState());
  };

  const onClickClearResults = () => {
    deleteTimeRecordsRunLog("recognition");
  }

  const onClickRefresh = () => {
    getCalculateTimeRecordsRunLog("recognition");
  }

  useEffect(() => {
    getCalculateTimeRecordsRunLog("recognition");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    timeBatchProcessRunLogState,
    formState,
    errorState,
    isLoading: timeBatchProcessRunLogState.isArrayLoading,
    allowProcessing: timeBatchProcessRunLogState.arrayData.length === 0,
    allowClear: timeBatchProcessRunLogState.arrayData.length > 1,
    allowRefresh: timeBatchProcessRunLogState.arrayData.length === 1,
    onFormValueChanged,
    onRunProcess,
    onCancel,
    onClickClearResults,
    onClickRefresh
  };

  function initialFormState() {
    return {
      meetId: '',
      personId: '',
      startDate: Constants.BLANK_DATE_STRING,
      endDate: Constants.BLANK_DATE_STRING
    };
  };
};

export default UseTimeBatchProcessTimeRecognitions;