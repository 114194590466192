import React, { Fragment } from 'react';

import useKpiMeasureDetail from './UseKpiMeasureDetail';

import BasicKpiDetail from './BasicKpiDetail';

import HeadCoachReportGrid from '../../../../../common/components/grids/kpis/HeadCoachReportGrid';
import ClubStaffReportGrid from '../../../../../common/components/grids/kpis/ClubStaffReportGrid';
import StaffRoleReportGrid from '../../../../../common/components/grids/kpis/StaffRoleReportGrid';
import CoachRosterReportGrid from '../../../../../common/components/grids/kpis/CoachRosterReportGrid';

import Constants from '../../../../../common/utils/Constants';

const KpiMeasureDetail = ({ orgUnitId, categoryMeasure, programLevelInstanceId }) => {
  const {
    kpiMeasureState,
    KPI_BASIC_MEASURE_TYPE_ID,
    KPI_COACH_ROSTER_MEASURE_TYPE_ID,
    KPI_COACH_YEARS_MEASURE_TYPE_ID
  } = useKpiMeasureDetail(categoryMeasure, programLevelInstanceId);

  return (
    <div className="row usas-extra-top-margin usas-extra-bottom-margin">
      <div className="col-xs-12">
        {categoryMeasure?.measureTypeId === KPI_BASIC_MEASURE_TYPE_ID
          ? (
            <BasicKpiDetail
              orgUnitId={orgUnitId}
              categoryMeasure={categoryMeasure}
              kpiMeasureState={kpiMeasureState} />
          ) : categoryMeasure?.measureTypeId === KPI_COACH_ROSTER_MEASURE_TYPE_ID
            ? (
              <CoachRosterReportGrid
                data={kpiMeasureState.arrayData}
                isLoading={kpiMeasureState.isArrayLoading} />
            ) : categoryMeasure?.measureTypeId === KPI_COACH_YEARS_MEASURE_TYPE_ID
              ? (
                <HeadCoachReportGrid
                  data={kpiMeasureState.arrayData}
                  isLoading={kpiMeasureState.isArrayLoading} />
              ) : categoryMeasure?.measureTypeName === Constants.KPI_CLUB_STAFF_MEASURE_TYPE_NAME
                ? (
                  <ClubStaffReportGrid
                    state={kpiMeasureState} />
                ) : categoryMeasure?.measureTypeName === Constants.KPI_STAFF_ROLE_MEASURE_TYPE_NAME
                  ? (
                    <StaffRoleReportGrid
                      data={kpiMeasureState.arrayData}
                      isLoading={kpiMeasureState.isArrayLoading} />
                  ) : <Fragment />
        }
      </div>
    </div>
  );
};

export default KpiMeasureDetail;