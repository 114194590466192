import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import { navLinks } from '../../../UseNavLinks';

import useGroupScholasticAllAmericanInviteData from '../../../state/workflow/groupScholasticAllAmericanInvite/UseGroupScholasticAllAmericanInviteData';
import useGroupScholasticAllAmericanFiltersData from '../../../state/workflow/groupScholasticAllAmericanInvite/groupScholasticAllAmericanFilters/UseGroupScholasticAllAmericanFiltersData';

import { DEFAULT_ID } from '../../../../common/utils/Constants';

const INITIAL_STATE = {
  gridData: [],
  zoneOptions: [],
  competitionCategoryOptions: [],
};

const useGroupScholasticAllAmericanInvite = () => {
  const navigate = useNavigate();
  const { groupScholasticAllAmericanInviteState, getGroupScholasticAllAmericanInvites
  } = useGroupScholasticAllAmericanInviteData();
  const { groupScholasticAllAmericanFiltersState, setFilters } = useGroupScholasticAllAmericanFiltersData();
  const [state, setState] = useState(INITIAL_STATE);

  const onGroupInviteClicked = (e, groupInvite) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(navLinks.GROUP_SCHOLASTIC_ALL_AMERICA_INVITE_APPROVALS, { state: { groupInvite } });
  };

  const onDropdownValueChange = (value, valuePropertyName, valueLabel, valueLabelPropertyName) => {
    const newFilterState = setFilters({
      [valuePropertyName]: value,
      [valueLabelPropertyName]: valueLabel
    });

    let gridData = JSON.parse(JSON.stringify(groupScholasticAllAmericanInviteState.arrayData));

    if (newFilterState.zoneOrgUnitCode !== DEFAULT_ID && newFilterState.zoneOrgUnitCode) {
      gridData = JSON.parse(JSON.stringify(gridData.filter(x => x.zoneOrgUnitCode === newFilterState.zoneOrgUnitCode)));
    }

    if (newFilterState.competitionGenderTypeCode !== DEFAULT_ID && newFilterState.competitionGenderTypeCode) {
      gridData = JSON.parse(JSON.stringify(gridData.filter(x => x.competitionGenderTypeCode === newFilterState.competitionGenderTypeCode)));
    }

    setState({
      ...state,
      gridData
    });
  };

  useEffect(() => {
    if (groupScholasticAllAmericanInviteState.isArrayLoaded !== true) {
      getGroupScholasticAllAmericanInvites();
    } else {
      const zoneOptions = getDropdownOptions(groupScholasticAllAmericanInviteState.arrayData, 'zoneOrgUnitCode', 'zoneOrgUnitName');
      const competitionCategoryOptions = getDropdownOptions(groupScholasticAllAmericanInviteState.arrayData, 'competitionGenderTypeCode', 'competitionGenderTypeName');
      const currentZoneFilter = zoneOptions.find(x => x.id === groupScholasticAllAmericanFiltersState.zoneOrgUnitCode);
      const currentCompetitionCategoryFilter = competitionCategoryOptions.find(x => x.id === groupScholasticAllAmericanFiltersState.competitionGenderTypeCode);
      const newFilterState = setFilters({
        zoneOrgUnitCode: currentZoneFilter?.id || DEFAULT_ID,
        zoneOrgUnitName: currentZoneFilter?.name || '',
        competitionGenderTypeCode: currentCompetitionCategoryFilter?.id || DEFAULT_ID,
        competitionGenderTypeName: currentCompetitionCategoryFilter?.name || '--'
      });

      let gridData = JSON.parse(JSON.stringify(groupScholasticAllAmericanInviteState.arrayData));

      if (newFilterState.zoneOrgUnitCode !== DEFAULT_ID && newFilterState.zoneOrgUnitCode) {
        gridData = JSON.parse(JSON.stringify(gridData.filter(x => x.zoneOrgUnitCode === newFilterState.zoneOrgUnitCode)));
      }

      if (newFilterState.competitionGenderTypeCode !== DEFAULT_ID && newFilterState.competitionGenderTypeCode) {
        gridData = JSON.parse(JSON.stringify(gridData.filter(x => x.competitionGenderTypeCode === newFilterState.competitionGenderTypeCode)));
      }

      setState({
        ...state,
        gridData,
        zoneOptions,
        competitionCategoryOptions
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupScholasticAllAmericanInviteState.isArrayLoaded]);

  function getDropdownOptions(arrayData, codeFieldName, nameFieldName) {
    const options = [{ id: DEFAULT_ID, name: '--' }];

    for (const application of arrayData) {
      if (!options.find(x => x.id === application[codeFieldName])) {
        options.push({
          id: application[codeFieldName],
          name: application[nameFieldName]
        });
      }
    }

    return options;
  };

  return {
    state,
    dropdownState: groupScholasticAllAmericanFiltersState,
    groupScholasticAllAmericanInviteState,
    onGroupInviteClicked,
    onDropdownValueChange
  };
};

export default useGroupScholasticAllAmericanInvite;