/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import useNewClubRegistrationApproval from './UseNewClubRegistrationApproval';

import { navLinks } from '../../../UseNavLinks';

import WorkFlowNavigation from '../components/navigation/WorkFlowNavigation';
import ClubRegistrationApprovalForm from '../components/forms/clubRegistrationApprovalForm/ClubRegistrationApprovalForm';

import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import ViewReport from '../../../../common/components/embeddedReports/ViewReport';

const NewClubRegistrationApprovalHQ = () => {
  const {
    Constants,
    isLoading,
    isSaving,
    newClubRegistrationState,
    clubRegObjState,
    formState,
    errorState,
    viewState,
    setViewState,
    INITIAL_VIEW_STATE,
    onBackClicked,
    onFormValueChanged,
    onApprovedClicked,
    onRequestClicked,
    onDownloadApplication
  } = useNewClubRegistrationApproval(navLinks.HQ_NEW_CLUB_REGISTRATION);

  return (
    <div className="row">
      <WorkFlowNavigation headerTitle={"HQ - New Club Registration"}>
        {newClubRegistrationState.isObjLoaded === true &&
          <form noValidate>
            <ClubRegistrationApprovalForm
              clubRegObj={clubRegObjState}
              formState={formState}
              errorState={errorState}
              onFormValueChanged={onFormValueChanged}
              onDownloadApplication={onDownloadApplication} />
            <div className="row usas-extra-top-margin usas-extra-bottom-margin">
              <div className="col-xs-12">
                <PrimaryButton type="button" onClick={onApprovedClicked}>Approve</PrimaryButton>&nbsp;
                <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>&nbsp;
                <PrimaryButton type="button" onClick={onRequestClicked}>Request Additional Info</PrimaryButton>
              </div>
            </div>
          </form>
        }
      </WorkFlowNavigation>
      {viewState.showReport === true &&
        <ViewReport reportInfoId={viewState.reportInfoId} reportParameters={viewState.reportParameters} onBackToGrid={() => setViewState({ INITIAL_VIEW_STATE })} />}
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </div>
  );
};

export default NewClubRegistrationApprovalHQ;