import React, { Fragment } from 'react';

import UseHqMemberClubTransferApproval from './UseHqMemberClubTransferApproval';

import MemberClubTransferApprovalForm from '../components/forms/memberClubTransferApprovalForm/MemberClubTransferApprovalForm';

import WorkFlowNavigation from '../components/navigation/WorkFlowNavigation';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Textarea from '../../../../common/components/inputs/Textarea';

import global from '../../../../common/components/GlobalStyle.module.css';

const HqMemberClubTransferApproval = () => {
  const {
    Constants,
    isSaving,
    isLoading,
    personClubTransferState,
    formState,
    errorState,
    attachDateState,
    onLastCompetitionDateChanged,
    onBackClicked,
    onRejectClicked,
    onApprovedClicked,
    onFromValueChanged,
    onRejectModalCancelClicked,
    onRejectModalConfirmClicked
  } = UseHqMemberClubTransferApproval();

  return (
    <div className="row">
      <WorkFlowNavigation headerTitle={"Club Transfer Request"}>
        <form noValidate>
          <MemberClubTransferApprovalForm
            transferObj={personClubTransferState.objData}
            attachDateState={attachDateState}
            formState={formState}
            errorState={errorState}
            onLastCompetitionDateChanged={onLastCompetitionDateChanged} />
          {errorState.errorOnSubmitAction &&
            <div className="row">
              <div className="col-xs-12">
                <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction || ''}</p>
              </div>
            </div>}
          <div className="row usas-extra-top-margin usas-extra-bottom-margin">
            <div className="col-xs-12">
              <PrimaryButton type="button" onClick={onApprovedClicked}>Approve</PrimaryButton>&nbsp;
              <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>&nbsp;
              <PrimaryButton type="button" onClick={onRejectClicked}>Reject</PrimaryButton>
            </div>
          </div>
        </form>
      </WorkFlowNavigation>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
      <PopUpModal
        widthPct={500}
        maxWidth={800}
        title="Reject Club Transfer"
        onModalCanceled={onRejectModalCancelClicked}
        displayPopUp={formState.isRejected}>
        <Fragment>
          <div className="row">
            <div className="col-xs-12">
              <Textarea
                className={global.Textarea}
                label="Reject Reason"
                name="stepNotes"
                value={formState.stepNotes}
                error={errorState.stepNotes}
                message={errorState.stepNotes}
                onChange={(value) => { onFromValueChanged('stepNotes', value); }} />
            </div>
          </div>
          <div className="row usas-extra-top-margin">
            <div className="col-xs-12">
              <PrimaryButton type="button" onClick={onRejectModalConfirmClicked}>Confirm</PrimaryButton>&nbsp;
              <SecondaryButton type="button" onClick={onRejectModalCancelClicked}>Cancel</SecondaryButton>
            </div>
          </div>
        </Fragment>
      </PopUpModal>
    </div>
  );
};

export default HqMemberClubTransferApproval;