/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import SGColumnHeader from '../../../../../../common/components/grids/SGColumnHeader';

import { formatDate } from '../../../../../../common/utils/DateFunctions';
import useSortableGrid from '../../../../../../common/utils/UseSortableGrid';
import SGConstants from '../../../../../../common/utils/SGConstants';

import global from '../../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ transferRequest, onRequestClicked }) => (
  <tr>
    <td><a onClick={(e) => onRequestClicked(e, transferRequest.workflowId)}>
      {transferRequest.firstName} {transferRequest.lastName}
    </a></td>
    <td>{formatDate(transferRequest.transferRequestDate)}</td>
    <td>{transferRequest.transferFromClubName}</td>
    <td>{transferRequest.transferToClubName}</td>
  </tr>
);

const LargeGrid = ({ data, isLoading, onRequestClicked }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(data);

  return (
    <table className={[global.UsasTable, 'visible-lg visible-md'].join(' ')}>
      <thead>
        <tr>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Name'} columnField={'lastName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Transfer Request Date'} columnField={'transferRequestDate'}
            sortType={SGConstants.SORT_TYPE_DATE} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Transfer From Club'} columnField={'transferFromClubName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Transfer To Club'} columnField={'transferToClubName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
        </tr>
      </thead>
      <tbody>
        {isLoading === true
          ? <tr><td colSpan="4">Loading...</td></tr>
          : sortableGridState.sortedGridData.length > 0
            ? sortableGridState.sortedGridData.map((transferRequest, i) => <GridRow key={i} transferRequest={transferRequest} onRequestClicked={onRequestClicked} />)
            : <tr><td colSpan="4">No Club Transfer Requests</td></tr>
        }
      </tbody>
    </table>
  );
};

export default LargeGrid;