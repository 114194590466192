import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import { navLinks } from '../../../UseNavLinks';

import UsePersonClubTransferData from '../../../state/workflow/personClubTransfer/UsePersonClubTransferData';
import useHqMemberClubTransferFilter from '../../../state/workflow/personClubTransfer/hqMemberClubTransferFilter/UseHqMemberClubTransferFilterData';

import useLscData from '../../../../common/state/lsc/UseLscData';

import Constants from '../../../../common/utils/Constants';

const INITIAL_DROPDOWN_STATE = {
  orgUnitId: '',
  orgUnitName: ''
};

const INITIAL_FILTERED_GRID_DATA_STATE = [];

const UseHqMemberClubTransfer = () => {
  const navigate = useNavigate();
  const { lscState } = useLscData();
  const { personClubTransferState, getHqPersonClubTransfer } = UsePersonClubTransferData();
  const { hqMemberClubTransferFilterState, updateFilters } = useHqMemberClubTransferFilter();
  const [dropdownState, setDropdownState] = useState(INITIAL_DROPDOWN_STATE);
  const [filteredGridDataState, setFilteredGridDataState] = useState(INITIAL_FILTERED_GRID_DATA_STATE);

  const onRequestClicked = (e, workflowId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    let targetRequest = null;
    for (const request of personClubTransferState.arrayData) {
      if (request.workflowId === workflowId) {
        targetRequest = request;
        break;
      }
    }

    if (targetRequest !== null) {
      navigate(navLinks.HQ_CLUB_TRANSFER_REQUEST_APPROVALS, { state: { targetRequest } });
    }
  };

  const onDropdownValueChange = (value, valueLabel) => {
    setDropdownState({
      orgUnitId: value === -1 ? '' : value || '',
      orgUnitName: valueLabel
    });

    const filteredArray = JSON.parse(JSON.stringify(personClubTransferState.arrayData.filter((x) => x.transferToLscOrgUnitId === value)));

    setFilteredGridDataState(filteredArray);
    updateFilters(value === -1 ? '' : value || '', valueLabel);
  };

  useEffect(() => {
    if (personClubTransferState.isArrayLoaded !== true && personClubTransferState.isArrayLoading !== true) {
      getHqPersonClubTransfer();
    } else if (personClubTransferState.isArrayLoaded === true && lscState.isLoaded === true) {
      onDropdownValueChange(hqMemberClubTransferFilterState.orgUnitId || '', hqMemberClubTransferFilterState.orgUnitName || '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personClubTransferState, lscState.isLoaded]);

  return {
    Constants,
    dropdownState,
    personClubTransferState,
    filteredGridDataState,
    onRequestClicked,
    onDropdownValueChange
  };
};

export default UseHqMemberClubTransfer;