/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';

import HideIcon from '../../icons/HideIcon';
import ShowIcon from '../../icons/ShowIcon';

import global from '../../GlobalStyle.module.css';

const DetailTableRow = ({ categoryMeasure, onEdit }) => {
  return (
    <tr>
      <td>{categoryMeasure.usesWorkflow === true ? <a onClick={(e) => onEdit(e, categoryMeasure)}>{categoryMeasure.measureName}</a> : categoryMeasure.measureName}</td>
      <td>{categoryMeasure.minValue} {categoryMeasure.minValue !== categoryMeasure.maxValue ? '- ' + categoryMeasure.maxValue : ''}</td>
      <td>{categoryMeasure.score >= 0 && categoryMeasure.score !== null ? categoryMeasure.score : '0'}</td>
    </tr>
  )
}

const DetailTable = ({ categoryMeasure, onEdit }) => (
  <table className={global.DetailTable}>
    <thead>
      <tr>
        <th>Task Name</th>
        <th>Point Range</th>
        <th>Current Points</th>
      </tr>
    </thead>
    <tbody>
      {categoryMeasure.map((categoryMeasure, i) =>
        <DetailTableRow key={i} categoryMeasure={categoryMeasure} onEdit={onEdit} />)}
    </tbody>
  </table>
);

const TableRow = ({ index, programCategory, onEdit, expandedId, onClick }) => {
  return (
    <Fragment>
      <tr key={index}>
        <td>{programCategory.categoryName}</td>
        <td>{programCategory.minimumValue}</td>
        <td>{programCategory.currentPointValue}</td>
        <td>
          <button
            className={global.IconButtonMargin}
            type="button"
            onClick={(e) => onClick(e, programCategory.programCategoryId)}>
            {expandedId === programCategory.programCategoryId ? <HideIcon /> : <ShowIcon />}
          </button>
        </td>
      </tr>
      {Array.isArray(programCategory.categoryMeasure) && programCategory.categoryMeasure.length > 0
        && expandedId === programCategory.programCategoryId &&
        <tr className={global.Expanded}>
          <td colSpan="5">
            <DetailTable categoryMeasure={programCategory.categoryMeasure} onEdit={onEdit} />
          </td>
        </tr>}
    </Fragment>
  );
};

const ProgramCategoryGridLarge = ({ state, onEdit, expandedId, onClick }) => (
  <Fragment>
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <th>Program Category Name</th>
          <th>Minimum Points</th>
          <th>Current Points</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {state.isObjLoaded !== true
          ? (<tr>
            {state.isObjLoading === true
              ? <td colSpan="4">Loading...</td>
              : <td colSpan="4">No Program Categories</td>}
          </tr>)
          : Array.isArray(state.objData.programCategory) && state.objData.programCategory.length > 0
            ? state.objData.programCategory.map((programCategory, i) =>
              <TableRow key={i} index={i} programCategory={programCategory} onEdit={onEdit}
                expandedId={expandedId} onClick={onClick} />)
            : (<tr>
              <td colSpan="4">No Program Categories</td>
            </tr>)}
      </tbody>
    </table>
  </Fragment>
);

export default ProgramCategoryGridLarge;