import { useContext } from "react";

import { GroupScholasticAllAmericanFiltersStateContext } from "./GroupScholasticAllAmericanFiltersContextProvider";

const useGroupScholasticAllAmericanFiltersData = () => {
  const [groupScholasticAllAmericanFiltersState, setGroupScholasticAllAmericanFiltersState] = useContext(GroupScholasticAllAmericanFiltersStateContext);

  const setFilters = (values) => {
    const newFilterState = {
      ...groupScholasticAllAmericanFiltersState,
      ...values
    };

    setGroupScholasticAllAmericanFiltersState({
      ...newFilterState
    });

    return newFilterState;
  };

  return {
    groupScholasticAllAmericanFiltersState,
    setFilters
  };
};

export default useGroupScholasticAllAmericanFiltersData;