import { API_NAMES, initApi } from '../../../../common/utils/HttpApiHelper';
import { BASIC_INITIAL_STATE } from '../../../../common/utils/HttpHelper';

import getPersonClubTransferData from './GetPersonClubTransferData';
import getHqPersonClubTransferData from './GetHqPersonClubTransferData';
import postPersonClubTransferData from './PostPersonClubTransferData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const getPersonClubTransferWorkflowData = (workflowId, state, setState) => {
  if (workflowId > 0) {
    const api = initApi(API_NAMES.COMMON, state, setState);
    const workflowIdForUrl = encodeURIComponent(workflowId);
    const url = `/Workflow/PersonClubTransfer/0/Workflow/${workflowIdForUrl}`;

    return api?.executeObject ? api.executeObject(url, 'GET') : null;
  }
};

const PersonClubTransferData = {
  INITIAL_STATE,
  getPersonClubTransferData,
  getHqPersonClubTransferData,
  getPersonClubTransferWorkflowData,
  postPersonClubTransferData
};

export default PersonClubTransferData;