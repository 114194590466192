import { useState } from 'react';

import KpiMeasureData from './KpiMeasureData';

const useKpiMeasureData = () => {
  const [kpiMeasureState, setKpiMeasureState] = useState(KpiMeasureData.INITIAL_STATE);

  const getKpiMeasure = (programLevelInstanceId, categoryMeasureId, kpiUrl) => {
    KpiMeasureData.getKpiMeasureData(programLevelInstanceId, categoryMeasureId, kpiUrl, kpiMeasureState, setKpiMeasureState);
  };

  return {
    kpiMeasureState,
    getKpiMeasure
  };
};

export default useKpiMeasureData;