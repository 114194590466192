import React, { Fragment } from 'react';

import HideIcon from '../../icons/HideIcon';
import ShowIcon from '../../icons/ShowIcon';
import EditIcon from '../../icons/EditIcon';

import global from '../../GlobalStyle.module.css';

const DetailTableRow = ({ categoryMeasure, onEdit }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableDetailRowHead}>
      <span className='hidden-xs'>{categoryMeasure.measureName && categoryMeasure.measureName.trim() !== '' ?
        categoryMeasure.measureName : <span>&nbsp;</span>}</span>&nbsp;
      <div className={global.FloatRight}>
        {categoryMeasure.usesWorkflow === true ?
          <button
            type="button"
            className={global.IconButton}
            onClick={(e) => onEdit(e, categoryMeasure)}>
            <EditIcon />
          </button> : <Fragment />}
      </div>
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
          Task Name
        </div>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
          {categoryMeasure.measureName && categoryMeasure.measureName.trim() !== '' ?
            categoryMeasure.measureName : <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Minimum Points</div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{categoryMeasure.minValue >= 0 ?
          categoryMeasure.minValue : <span>&nbsp;</span>}</div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Current Points</div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{categoryMeasure.score >= 0 &&
          categoryMeasure.score !== null ? categoryMeasure.score : '0'}</div>
      </div>
    </div >
  </div >
);

const DetailTable = ({ categoryMeasure, onEdit }) => (
  <div className={global.SmallTableDetailTable}>
    {Array.isArray(categoryMeasure) && categoryMeasure.map((categoryMeasure, i) =>
      <DetailTableRow key={i} categoryMeasure={categoryMeasure}
        onEdit={onEdit} />)}
  </div>
);

const GridRow = ({ programCategory, onEdit, expandedId, onClick }) => {
  return (
    <Fragment>
      <div className={global.SmallTableRow} key={programCategory.programCategoryId}>
        <div className={global.SmallTableRowHead}><span className='hidden-xs'>{programCategory.categoryName || <span>&nbsp;</span>}</span>&nbsp;
          <button className={global.IconButton}
            type="button"
            onClick={(e) => onClick(e, programCategory.programCategoryId)}>
            {expandedId === programCategory.programCategoryId ? <HideIcon /> : <ShowIcon />}
          </button>
        </div>
        <div className={global.SmallTableRowInfo}>
          <div className='row'>
            <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
              Category Name
            </div>
            <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
              {programCategory.categoryName && programCategory.categoryName.trim() !== '' ?
                programCategory.categoryName : <span>&nbsp;</span>}
            </div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Minimum Points</div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
              {programCategory.minimumValue >= 0 ? programCategory.minimumValue : <span>&nbsp;</span>}
            </div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Current Points</div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
              {programCategory.currentPointValue >= 0 ? programCategory.currentPointValue : '0'}
            </div>
          </div>
        </div>
      </div>
      {Array.isArray(programCategory.categoryMeasure) && programCategory.categoryMeasure.length > 0
        && expandedId === programCategory.programCategoryId &&
        <DetailTable categoryMeasure={programCategory.categoryMeasure}
          onEdit={onEdit} />}
    </Fragment>
  );
}

const ProgramCategoryGridSmall = ({ state, onEdit, expandedId, onClick }) => (
  <Fragment>
    <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
      {state.isObjLoaded !== true
        ? (<div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            {state.isObjLoading === true
              ? <div className={global.SmallTableRowLabels}>Loading... </div>
              : <div className={global.SmallTableRowLabels}>No Program Categories</div>}
          </div>
        </div>
        ) : Array.isArray(state.objData.programCategory) && state.objData.programCategory.length > 0
          ? state.objData.programCategory.map((programCategory, i) =>
            <GridRow key={i} programCategory={programCategory}
              onEdit={onEdit} expandedId={expandedId} onClick={onClick} />)
          : (
            <div className={global.SmallTableRow}>
              <div className={global.SmallTableRowHead}>
                &nbsp;
              </div>
              <div className={global.SmallTableRowInfo}>
                <div className={global.SmallTableRowLabels}>No Program Categories</div>
              </div>
            </div>)
      }
    </div>
  </Fragment>
);

export default ProgramCategoryGridSmall;