import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import validate from './ClubRecognitionCategoryMeasureApprovalsValidation';

import { navLinks } from '../../../UseNavLinks';

import useCategoryMeasureScoreData from '../../../../common/state/categoryMeasureScore/UseCategoryMeasureScoreData';
import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';

import Constants from '../../../../common/utils/Constants';
import useForm from '../../../../common/utils/UseForm';
import { formatDate } from '../../../../common/utils/DateFunctions';

const GET_REC_FILES_PATH = '/RecFiles?fileUrl=';

const useClubRecognitionCategoryMeasureApprovals = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { categoryMeasureScoreState, putCategoryMeasureScore } = useCategoryMeasureScoreData();
  const { environmentVariableState, FILE_UPLOAD_MEASURE_TYPE_ID } = useEnvironmentVariableData();
  const { formState, errorState, updateFormState, setFormData, handleSubmit
  } = useForm(getInitialFormState, submitFormCallback, validate);
  const [state, setState] = useState({
    categoryMeasure: {}, programLevel: {}, qualifiedOrgUnitCode: '',
    clubName: '', submissionDate: '', tryRedirect: false, isFileUpload: false
  });

  const createCategoryMeasureScoreObj = () => {
    let obj = {};

    obj = {
      categoryMeasureScoreId: state.categoryMeasure?.categoryMeasureScoreId,
      programLevelInstanceId: state.programLevel?.programLevelInstanceId,
      categoryMeasureId: state.categoryMeasure?.categoryMeasureId,
      submittedValue: state.categoryMeasure?.submittedValue,
      submittedById: null,
      categoryMeasure: undefined,
      score: formState.score,
      notes: formState.notes.trim()
    }

    return obj;
  };

  const onBackClicked = () => {
    navigate(navLinks.CLUB_RECOGNITION_REQUEST);
  };

  const onCancelClicked = () => {
    navigate(navLinks.CLUB_RECOGNITION_PROGRAM_LEVEL, { state: { programLevel: state.programLevel } });
  };

  const onDownloadUploadedFile = (event) => {
    event.preventDefault();
    const fileUrl = state.categoryMeasure?.submittedValue;
    window.location.href = import.meta.env.VITE_RECAPI_URL + GET_REC_FILES_PATH + fileUrl;
  };

  useEffect(() => {
    if (location.state && location.state.categoryMeasure &&
      location.state.programLevel && environmentVariableState.isLoaded === true) {
      const categoryMeasure = location.state.categoryMeasure;
      const programLevel = location.state.programLevel;
      setState({
        ...state,
        categoryMeasure: categoryMeasure,
        programLevel: programLevel,
        qualifiedOrgUnitCode: programLevel.qualifiedOrgUnitCode,
        clubName: programLevel.clubName,
        submissionDate: formatDate(programLevel.submissionDate),
        isFileUpload: categoryMeasure?.measureTypeId === FILE_UPLOAD_MEASURE_TYPE_ID ? true : false
      });
      setFormData({
        ...formState,
        score: categoryMeasure.score >= 0 ? categoryMeasure.score : '',
        notes: categoryMeasure.notes || '',
        minValue: categoryMeasure.minValue,
        maxValue: categoryMeasure.maxValue,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environmentVariableState]);

  useEffect(() => {
    if (state.tryRedirect === true && categoryMeasureScoreState.isSaved === true) {
      navigate(navLinks.CLUB_RECOGNITION_PROGRAM_LEVEL, { state: { programLevel: state.programLevel } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, categoryMeasureScoreState]);

  function submitFormCallback() {
    putCategoryMeasureScore(state.categoryMeasure.categoryMeasureScoreId, createCategoryMeasureScoreObj());
    setState({ ...state, tryRedirect: true });
  };

  function getInitialFormState() {
    return {
      score: '',
      notes: '',
      minValue: 0,
      maxValue: 0
    };
  };

  return {
    Constants,
    state,
    categoryMeasureScoreState,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged: updateFormState,
    onBackClicked,
    onCancelClicked,
    onDownloadUploadedFile
  };
};

export default useClubRecognitionCategoryMeasureApprovals;