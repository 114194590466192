import React, { Fragment } from 'react';

import useExpandedRow from '../../../../../../common/components/grids/UseExpandedRow';

import LargeGrid from './LargeGrid';
import SmallGrid from './SmallGrid';

const InboxGrids = ({ messageState, onDeleteClick, onReadMessage }) => {
  const { expandedId, onClick } = useExpandedRow();

  return (
    <Fragment>
      <LargeGrid
        messageState={messageState}
        expandedId={expandedId}
        onClick={onClick}
        onDeleteClick={onDeleteClick}
        onReadMessage={onReadMessage} />
      <SmallGrid
        messageState={messageState}
        expandedId={expandedId}
        onClick={onClick}
        onDeleteClick={onDeleteClick}
        onReadMessage={onReadMessage} />
    </Fragment>
  );
};

export default InboxGrids;