/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';

import HideIcon from '../../../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../../../common/components/icons/ShowIcon';
import DeleteIcon from '../../../../../../common/components/icons/DeleteIcon';
import { formatDate } from '../../../../../../common/utils/DateFunctions';

import localConstants from '../../../../../Constants';

import global from '../../../../../../common/components/GlobalStyle.module.css';

import style from '../Grid.module.css';

const DetailTableRow = ({ message }) => {
  return (
    <tr>
      <td>{message}</td>
    </tr>
  );
};

const DetailTable = ({ message }) => (
  <table className={global.DetailTable}>
    <thead>
      <tr>
        <th>Message</th>
      </tr>
    </thead>
    <tbody>
      <DetailTableRow message={message} />
    </tbody>
  </table>
);

const TableRow = ({ message, onClick, expandedId, onDeleteClick, onReadMessage }) => {
  return (
    <Fragment>
      <tr className={message.status !== localConstants.MESSAGE_READ_STATUS_TEXT ? global.Bold : style.ReadBackground}>
        <td className={style.OverwriteFirstChildStyle}>{formatDate(message.dateSent)}</td>
        <td>{message.subject}</td>
        <td>{message.notificationTypeName}</td>
        <td>
          <button
            className={global.IconButtonMargin}
            type="button"
            onClick={e => onDeleteClick(message)}>
            <DeleteIcon />
          </button>
          <button
            className={global.IconButtonMargin}
            type="button"
            onClick={e => { onClick(e, message.id); onReadMessage(message) }}>
            {expandedId === message.id
              ? <HideIcon />
              : <ShowIcon />}
          </button>
        </td>
      </tr>
      <tr className={[global.DetailRow, (expandedId === message.id ? global.Expanded : global.Collapsed)].join(' ')}>
        <td colSpan="7">
          <DetailTable message={message.message} />
        </td>
      </tr>
    </Fragment>
  );
};

const LargeGrid = ({ messageState, onClick, expandedId, onDeleteClick, onReadMessage }) => (
  <Fragment>
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <th>Date</th>
          <th>Subject</th>
          <th>Type</th>
          <th></th>
        </tr>
      </thead>
      <tbody className={style.OverwriteHover}>
        {messageState.isArrayLoaded !== true
          ? (<tr>
            {messageState.isArrayLoading === true
              ? <td colSpan="7">Loading...</td>
              : <Fragment />}
          </tr>)
          : Array.isArray(messageState.arrayData) && messageState.arrayData.length > 0
            ? messageState.arrayData.map((message, i) =>
              <TableRow
                key={i}
                message={message}
                onClick={onClick}
                expandedId={expandedId}
                onDeleteClick={onDeleteClick}
                onReadMessage={onReadMessage} />)
            : (<tr>
              {<td colSpan="7">No Messages</td>}
            </tr>)}
      </tbody>
    </table>
  </Fragment>
);

export default LargeGrid;