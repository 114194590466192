import { useState } from 'react';

import MeetSanctionData from './MeetSanctionData';

const UseMeetSanctionData = () => {
  const [meetSanctionState, setMeetSanctionState] = useState(MeetSanctionData.INITIAL_STATE);

  const getMeetSanction = () => {
    MeetSanctionData.getMeetSanction(meetSanctionState, setMeetSanctionState);
  };

  const getMeetSanctionWorkflow = (workflowId) => {
    MeetSanctionData.getMeetSanctionWorkflow(workflowId, meetSanctionState, setMeetSanctionState);
  };

  const putMeetSanctionWorkflow = (workflowId, workflowObj) => {
    MeetSanctionData.putMeetSanctionWorkflow(workflowId, workflowObj, meetSanctionState, setMeetSanctionState);
  };

  const clearObjData = () => {
    setMeetSanctionState({
      ...meetSanctionState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {}
    });
  };

  const clearArrayData = () => {
    setMeetSanctionState({
      ...meetSanctionState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: []
    });
  };

  const confirmSave = () => {
    setMeetSanctionState({
      ...meetSanctionState,
      isSaved: false
    });
  };

  return {
    meetSanctionState,
    clearArrayData,
    clearObjData,
    confirmSave,
    getMeetSanction,
    getMeetSanctionWorkflow,
    putMeetSanctionWorkflow
  };
};

export default UseMeetSanctionData;