import React, { createContext, useState } from 'react';

import TimeBatchProcessData from './TimeBatchProcessData';

export const TimeBatchProcessStateContext = createContext();

const TimeBatchProcessContextProvider = ({ children }) => {
  const stateHook = useState(TimeBatchProcessData.INITIAL_STATE);

  return (
    <TimeBatchProcessStateContext.Provider value={stateHook}>
      {children}
    </TimeBatchProcessStateContext.Provider>
  );
};

export default TimeBatchProcessContextProvider;