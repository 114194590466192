import getLTSProviderRegistrationWorkflowData from '../GetWorkFlowByIdData';
import putLTSProviderRegistrationWorkflowData from '../PutWorkFlowData';

import { API_NAMES, initApi } from '../../../../common/utils/HttpApiHelper';

const getLTSProviderRegistrationsData = (state, setState) => {
  const api = initApi(API_NAMES.COMMON, state, setState);
  const url = `/Workflow/LearnToSwim/0`;

  return api?.executeArray ? api.executeArray(url, 'GET') : null;
};

const LearnToSwimRegistrationData = {
  getLTSProviderRegistrationsData,
  getLTSProviderRegistrationWorkflowData,
  putLTSProviderRegistrationWorkflowData
};

export default LearnToSwimRegistrationData;