import React, { Fragment } from 'react';

import EditIcon from '../../../../../../common/components/icons/EditIcon';

import { formatDate } from '../../../../../../common/utils/DateFunctions';

import global from '../../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ meetSanc, onSanctionClicked }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}>
      <span className='hidden-xs'>{`${meetSanc.lscCode || ''} - ${meetSanc.clubName || ''}/${meetSanc.clubCode || ''}`}</span>&nbsp;
      <button
        className={global.IconButton}
        type="button"
        onClick={e => onSanctionClicked(e, meetSanc)}>
        <EditIcon />
      </button>
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={[global.SmallTableRowLabels, 'col-xs-12 visible-xs'].join(' ')}>LSC - Club Name/Club Code</div>
        <div className={[global.SmallTableRowData, 'col-xs-12 visible-xs'].join(' ')}>{`${meetSanc.lscCode || ''} - ${meetSanc.clubName || ''}/${meetSanc.clubCode || ''}`}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Submitted Date</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{formatDate(meetSanc.submissionDate)}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Meet Name</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{meetSanc.meetName}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Meet Dates</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-9'].join(' ')}>{`${formatDate(meetSanc.meetStartDate)} - ${formatDate(meetSanc.meetEndDate)}`}</div>
      </div>
    </div>
  </div>
);

const SmallGrid = ({ data, isLoading, onSanctionClicked }) => (
  <Fragment>
    <div className={[global.SmallTable, 'hidden-lg hidden-md'].join(' ')}>
      {isLoading === true
        ? (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>Loading...</div>
            </div>
          </div>
        )
        : data.length > 0
          ? data.map((meetSanc, i) => <GridRow key={i} meetSanc={meetSanc} onSanctionClicked={onSanctionClicked} />)
          : (
            <div className={global.SmallTableRow}>
              <div className={global.SmallTableRowHead}>&nbsp;</div>
              <div className={global.SmallTableRowInfo}>
                <div className={global.SmallTableRowLabels}>No Meet Sanction Requests</div>
              </div>
            </div>
          )
      }
    </div>
  </Fragment>
);

export default SmallGrid;