import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Navigate } from '../../../common/wrappers/ReactRouterDom';

import TimeBatchProcessContextView from './TimeBatchProcessContextView';

import TimeBatchProcessTimeRecognitions from './timeBatchProcessTimeRecognitions/TimeBatchProcessTimeRecognitions';
import TimeBatchAssignments from './timeBatchProcessTimeRecords/TimeBatchProcessTimeRecords';

import TimeBatchProcessContextProvider from '../../state/timeBatchProcess/TimeBatchProcessContextProvider';

import LoadingModal from '../../../common/components/dialogs/LoadingModal';
import SecuredRouteElement from '../../../common/components/security/SecuredRouteElement';

import useNavRoutes from '../../../common/state/security/UseNavRoutes';

const TimeBatchProcessContextRoutes = ({ navRoutes }) => (
  <TimeBatchProcessContextView>
    <Routes>
      <Route path={navRoutes['BATCH_PROCESS_TIME_RECOGNITIONS']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['BATCH_PROCESS_TIME_RECOGNITIONS']}
            editElement={<TimeBatchProcessTimeRecognitions />} />
        }
      />
      <Route path={navRoutes['BATCH_PROCESS_TIME_RECORDS']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['BATCH_PROCESS_TIME_RECORDS']}
            editElement={<TimeBatchAssignments />} />
        }
      />
    </Routes>
  </TimeBatchProcessContextView>
);

const TimeBatchProcessRoutes = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return (
    <Routes>
      <Route path={"/*"} element={<TimeBatchProcessContextRoutes navRoutes={navRoutes} />} />
      <Route path={'/'} element={<Navigate to={navRoutes['BATCH_PROCESS_TIME_RECOGNITIONS']?.route} replace />} />
    </Routes>
  );
};

const TimeBatchProcess = () => (
  <TimeBatchProcessContextProvider>
    <TimeBatchProcessRoutes />
  </TimeBatchProcessContextProvider>
);

export default TimeBatchProcess;