import { Fragment } from "react";

import useTimeBatchProcessTimeRecognitions from './UseTimeBatchProcessTimeRecognitions';

import Input from '../../../../common/components/inputs/Input';
import DatePicker from '../../../../common/components/datepickers/DatePicker';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import Headings from "../../../../common/components/headings/Headings";
import PopUpModal from "../../../../common/components/dialogs/PopUpModal";
import Constants from "../../../../common/utils/Constants";
import global from '../../../../common/components/GlobalStyle.module.css';

const getColorForMessage = (type) => {
  if (type === "timerecognition") {
    return 'lightblue'
  } else if (type === "relaytimerecognition") {
    return 'lightgrey'
  } else return 'ivory'
}

const GridRow = ({ log }) => (
  <tr>
    <td style={{ backgroundColor: getColorForMessage(log.type) }}>{log.message}</td>
  </tr>
);

const TimeBatchProcessTimeRecognitions = () => {
  const {
    timeBatchProcessRunLogState,
    formState,
    errorState,
    isLoading,
    allowProcessing,
    allowClear,
    allowRefresh,
    onFormValueChanged,
    onRunProcess,
    onCancel,
    onClickClearResults,
    onClickRefresh
  } = useTimeBatchProcessTimeRecognitions();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Assign Swim Time Recognition</Headings.H3>
        </div>
      </div>
      <form noValidate onSubmit={onRunProcess}>
        {allowProcessing === true &&
          <div className="row usas-extra-bottom-margin">
            <div className="col-md-4 col-sm-6 col-xs-12">
              <Input
                type={'number'}
                label={'Meet Id'}
                value={formState.meetId}
                error={errorState.meetId}
                message={errorState.meetId}
                name={'meetId'}
                onChange={value => onFormValueChanged('meetId', value)} />
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <Input
                type={'number'}
                label={'Person Id'}
                value={formState.personId}
                error={errorState.personId}
                message={errorState.personId}
                name={'personId'}
                onChange={value => onFormValueChanged('personId', value)} />
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <DatePicker
                label={'Start Date'}
                value={formState.startDate}
                error={errorState.startDate}
                message={errorState.startDate}
                name={'startDate'}
                onChange={value => onFormValueChanged('startDate', value)} />
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <DatePicker
                label={'End Date'}
                value={formState.endDate}
                error={errorState.endDate}
                message={errorState.endDate}
                name={'endDate'}
                onChange={value => onFormValueChanged('endDate', value)} />
            </div>
          </div>
        }
        <div className="row">
          <div className="col-xs-12" style={{ fontSize: 18 }}>
            {allowProcessing === true &&
              <>
                <PrimaryButton type='button' onClick={onRunProcess}>Process Records</PrimaryButton>&nbsp;
                <SecondaryButton type="button" onClick={onCancel}>Cancel</SecondaryButton>
              </>
            }
            {allowClear === true &&
              <SecondaryButton type='button' onClick={onClickClearResults}>Clear Results</SecondaryButton>
            }
            {allowRefresh === true &&
              <div style={{ fontSize: 21, color: "#444444" }}>
                <>
                  This can take over 5 minutes to complete.
                  After starting the process, you can work on other tasks and return later to see the results.
                  Or, stay here and click the 'REFRESH' button.
                </><br /><br />
                <SecondaryButton type='button' onClick={onClickRefresh}>Refresh</SecondaryButton>
              </div>
            }
          </div>
        </div>
      </form>
      <table className={[global.UsasTable, 'visible-xs visible-sm visible-md visible-lg'].join(' ')}>
        <tbody>
          {isLoading === true
            ? (
              <tr>
                <td colSpan="2">Processing...</td>
              </tr>
            ) : Array.isArray(timeBatchProcessRunLogState.arrayData) && timeBatchProcessRunLogState.arrayData.length > 0
              ?
              timeBatchProcessRunLogState.arrayData.map((log, i) => (
                <GridRow
                  key={i}
                  log={log} />
              )) : (
                <tr>
                  <td colSpan="2"></td>
                </tr>
              )
          }
        </tbody>
      </table>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.PROCESSING_MSG}
        displayPopUp={isLoading} />
    </Fragment >
  )
};

export default TimeBatchProcessTimeRecognitions;