import Constants from '../../utils/Constants';
import { BASIC_INITIAL_STATE } from '../../utils/HttpHelper';

import getProgramLevelByProgramTypeData from './GetProgramLevelByProgramTypeData';
import getProgramLevelInstanceData from './GetProgramLevelInstanceData';
import postProgramLevelInitiationData from './PostProgramLevelInitiationData';
import postProgramLevelRenewalData from './PostProgramLevelRenewalData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  orgUnitId: '',
  programTypeId: Constants.DEFAULT_ID,
  programLevelInstanceId: Constants.DEFAULT_ID
};

const ProgramLevelData = {
  INITIAL_STATE,
  getProgramLevelByProgramTypeData,
  getProgramLevelInstanceData,
  postProgramLevelInitiationData,
  postProgramLevelRenewalData
};

export default ProgramLevelData;