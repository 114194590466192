import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Navigate } from '../../../common/wrappers/ReactRouterDom';

import HqMemberClubTransferFilterContextProvider from '../../state/workflow/personClubTransfer/hqMemberClubTransferFilter/HqMemberClubTransferFilterContextProvider';

import ProgramLevelContextProvider from '../../../common/state/programLevel/ProgramLevelContextProvider';
import CategoryMeasureScoreContextProvider from '../../../common/state/categoryMeasureScore/CategoryMeasureScoreContextProvider';
import MeetContextProvider from '../../../common/state/meet/MeetContextProvider';
import MeetFiltersContextProvider from '../../../common/state/meet/MeetFiltersContextProvider';
import OrgGroupPropertiesContextProvider from '../../../common/state/orgGroupProperties/OrgGroupPropertiesContextProvider';
import GroupScholasticAllAmericanFiltersContextProvider from '../../state/workflow/groupScholasticAllAmericanInvite/groupScholasticAllAmericanFilters/GroupScholasticAllAmericanFiltersContextProvider';

import NewClubRegistrationHQ from './newClubRegistration/NewClubRegistrationHQ';
import NewClubRegistrationLSC from './newClubRegistration/NewClubRegistrationLSC';
import NewClubRegistrationApprovalHQ from './newClubRegistrationApproval/NewClubRegistrationApprovalHQ';
import NewClubRegistrationApprovalLSC from './newClubRegistrationApproval/NewClubRegistrationApprovalLSC';
import MemberClubTransfer from './memberClubTransfer/MemberClubTransfer';
import MemberClubTransferApproval from './memberClubTransferApproval/MemberClubTransferApproval';
import ExistingClubRegistration from './existingClubRegistration/ExistingClubRegistration';
import ExistingClubRegistrationApproval from './existingClubRegistrationApproval/ExistingClubRegistrationApproval';
import PreCompetitiveRegistration from './preCompetitiveRegistration/PreCompetitiveRegistration';
import PreCompetitiveRegistrationApproval from './preCompetitiveRegistrationApproval/PreCompetitiveRegistrationApproval';
import MeetSanction from './meetSanction/MeetSanction';
import MeetSanctionApproval from './meetSanctionApproval/MeetSanctionApproval';
import SafeSport from './safeSport/SafeSport';
import SafeSportProgramLevel from './safeSportProgramLevel/SafeSportProgramLevel';
import SafeSportProgramLevelApprovals from './safeSportProgramLevelApprovals/SafeSportProgramLevelApprovals';
import SafeSportCategoryMeasureApprovals from './safeSportCategoryMeasureApprovals/SafeSportCategoryMeasureApprovals';
import ClubRecognition from './clubRecognition/ClubRecognition';
import ClubRecognitionProgramLevel from './clubRecognitionProgramLevel/ClubRecognitionProgramLevel';
import ClubRecognitionProgramLevelApprovals from './clubRecognitionProgramLevelApprovals/ClubRecognitionProgramLevelApprovals';
import ClubRecognitionCategoryMeasureApprovals from './clubRecognitionCategoryMeasureApprovals/ClubRecognitionCategoryMeasureApprovals';
import GroupScholasticAllAmericanInvite from './groupScholasticAllAmericanInvite/GroupScholasticAllAmericanInvite';
import GroupNationalTeamInvite from './groupNationalTeamInvite/GroupNationalTeamInvite';
import GroupScholasticAllAmericanInviteApproval from './groupScholasticAllAmericanInviteApproval/GroupScholasticAllAmericanInviteApproval';
import GroupNationalTeamInviteApproval from './groupNationalTeamInviteApproval/GroupNationalTeamInviteApproval';
import LearnToSwimRegistration from './learnToSwimRegistration/LearnToSwimRegistration';
import LearnToSwimRegistrationApproval from './learnToSwimRegistrationApproval/LearnToSwimRegistrationApproval';
import ApprovalQueues from './approvalQueues/ApprovalQueues';
import HqMemberClubTransfer from './hqMemberClubTransfer/HqMemberClubTransfer';
import HqMemberClubTransferApproval from './hqMemberClubTransferApproval/HqMemberClubTransferApproval';

import LoadingModal from '../../../common/components/dialogs/LoadingModal';
import SecuredRouteElement from '../../../common/components/security/SecuredRouteElement';

import useNavRoutes from '../../../common/state/security/UseNavRoutes';
import LscContextProvider from '../../../common/state/lsc/LscContextProvider';

const ManageWorkFlows = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return (
    <Routes>
      <Route path={navRoutes.WORKFLOWS_QUEUE_LIST?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.WORKFLOWS_QUEUE_LIST}
            editElement={<ApprovalQueues />} />
        }
      />
      <Route path={navRoutes.WORKFLOWS_LSC_NEW_CLUB_REGISTRATION?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.WORKFLOWS_LSC_NEW_CLUB_REGISTRATION}
            editElement={<NewClubRegistrationLSC />} />
        }
      />
      <Route path={navRoutes.WORKFLOWS_HQ_NEW_CLUB_REGISTRATION?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.WORKFLOWS_HQ_NEW_CLUB_REGISTRATION}
            editElement={<NewClubRegistrationHQ />} />
        }
      />
      <Route path={navRoutes.WORKFLOWS_LSC_NEW_CLUB_REGISTRATION_APPROVALS?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.WORKFLOWS_LSC_NEW_CLUB_REGISTRATION_APPROVALS}
            editElement={<NewClubRegistrationApprovalLSC />} />
        }
      />
      <Route path={navRoutes.WORKFLOWS_HQ_NEW_CLUB_REGISTRATION_APPROVALS?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.WORKFLOWS_HQ_NEW_CLUB_REGISTRATION_APPROVALS}
            editElement={<NewClubRegistrationApprovalHQ />} />
        }
      />
      <Route path={navRoutes.WORKFLOWS_CLUB_TRANSFER_REQUEST?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.WORKFLOWS_CLUB_TRANSFER_REQUEST}
            editElement={<MemberClubTransfer />} />
        }
      />
      <Route path={navRoutes.WORKFLOWS_CLUB_TRANSFER_REQUEST_APPROVALS?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.WORKFLOWS_CLUB_TRANSFER_REQUEST_APPROVALS}
            editElement={<MemberClubTransferApproval />} />
        }
      />
      <Route path={navRoutes.WORKFLOWS_HQ_CLUB_TRANSFER_REQUEST?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.WORKFLOWS_HQ_CLUB_TRANSFER_REQUEST}
            editElement={<HqMemberClubTransfer />} />
        }
      />
      <Route path={navRoutes.WORKFLOWS_HQ_CLUB_TRANSFER_REQUEST_APPROVALS?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.WORKFLOWS_HQ_CLUB_TRANSFER_REQUEST_APPROVALS}
            editElement={<HqMemberClubTransferApproval />} />
        }
      />
      <Route path={navRoutes.WORKFLOWS_EXISTING_CLUB_REGISTRATION?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.WORKFLOWS_EXISTING_CLUB_REGISTRATION}
            editElement={<ExistingClubRegistration />} />
        }
      />
      <Route path={navRoutes.WORKFLOWS_EXISTING_CLUB_REGISTRATION_APPROVALS?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.WORKFLOWS_EXISTING_CLUB_REGISTRATION_APPROVALS}
            editElement={<ExistingClubRegistrationApproval />} />
        }
      />
      <Route path={navRoutes.WORKFLOWS_PRE_COMP_REGISTRATION?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.WORKFLOWS_PRE_COMP_REGISTRATION}
            editElement={<PreCompetitiveRegistration />} />
        }
      />
      <Route path={navRoutes.WORKFLOWS_PRE_COMP_REGISTRATION_APPROVALS?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.WORKFLOWS_PRE_COMP_REGISTRATION_APPROVALS}
            editElement={<PreCompetitiveRegistrationApproval />} />
        }
      />
      <Route path={navRoutes.WORKFLOWS_MEET_SANCTION_REQUEST?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.WORKFLOWS_MEET_SANCTION_REQUEST}
            editElement={<MeetSanction />} />
        }
      />
      <Route path={navRoutes.WORKFLOWS_MEET_SANCTION_REQUEST_APPROVALS?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.WORKFLOWS_MEET_SANCTION_REQUEST_APPROVALS}
            editElement={<MeetContextProvider>
              <MeetFiltersContextProvider>
                <MeetSanctionApproval />
              </MeetFiltersContextProvider>
            </MeetContextProvider>} />
        }
      />
      <Route path={navRoutes.WORKFLOWS_SAFE_SPORT_REQUEST?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.WORKFLOWS_SAFE_SPORT_REQUEST}
            editElement={<SafeSport />} />
        }
      />
      <Route path={navRoutes.WORKFLOWS_SAFE_SPORT_PROGRAM_LEVEL?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.WORKFLOWS_SAFE_SPORT_PROGRAM_LEVEL}
            editElement={<SafeSportProgramLevel />} />
        }
      />
      <Route path={navRoutes.WORKFLOWS_SAFE_SPORT_PROGRAM_LEVEL_APPROVALS?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.WORKFLOWS_SAFE_SPORT_PROGRAM_LEVEL_APPROVALS}
            editElement={<SafeSportProgramLevelApprovals />} />
        }
      />
      <Route path={navRoutes.WORKFLOWS_SAFE_SPORT_TASK_APPROVALS?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.WORKFLOWS_SAFE_SPORT_TASK_APPROVALS}
            editElement={<SafeSportCategoryMeasureApprovals />} />
        }
      />
      <Route path={navRoutes.WORKFLOWS_CLUB_RECOGNITION_REQUEST?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.WORKFLOWS_CLUB_RECOGNITION_REQUEST}
            editElement={<ClubRecognition />} />
        }
      />
      <Route path={navRoutes.WORKFLOWS_CLUB_RECOGNITION_PROGRAM_LEVEL?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.WORKFLOWS_CLUB_RECOGNITION_PROGRAM_LEVEL}
            editElement={<ClubRecognitionProgramLevel />} />
        }
      />
      <Route path={navRoutes.WORKFLOWS_CLUB_RECOGNITION_PROGRAM_LEVEL_APPROVALS?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.WORKFLOWS_CLUB_RECOGNITION_PROGRAM_LEVEL_APPROVALS}
            editElement={<ClubRecognitionProgramLevelApprovals />} />
        }
      />
      <Route path={navRoutes.WORKFLOWS_CLUB_RECOGNITION_TASK_APPROVALS?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.WORKFLOWS_CLUB_RECOGNITION_TASK_APPROVALS}
            editElement={<ClubRecognitionCategoryMeasureApprovals />} />
        }
      />
      <Route path={navRoutes.WORKFLOWS_GROUP_SCHOLASTIC_ALL_AMERICA_INVITE?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.WORKFLOWS_GROUP_SCHOLASTIC_ALL_AMERICA_INVITE}
            editElement={<GroupScholasticAllAmericanInvite />} />
        }
      />
      <Route path={navRoutes.WORKFLOWS_NATIONAL_TEAM_INVITE?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.WORKFLOWS_NATIONAL_TEAM_INVITE}
            editElement={<GroupNationalTeamInvite />} />
        }
      />
      <Route path={navRoutes.WORKFLOWS_GROUP_SCHOLASTIC_ALL_AMERICA_INVITE_APPROVALS?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.WORKFLOWS_GROUP_SCHOLASTIC_ALL_AMERICA_INVITE_APPROVALS}
            editElement={<GroupScholasticAllAmericanInviteApproval />} />
        }
      />
      <Route path={navRoutes.WORKFLOWS_NATIONAL_TEAM_INVITE_APPROVALS?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.WORKFLOWS_NATIONAL_TEAM_INVITE_APPROVALS}
            editElement={<GroupNationalTeamInviteApproval />} />
        }
      />
      <Route path={navRoutes.WORKFLOWS_LEARN_TO_SWIM_REGISTRATION?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.WORKFLOWS_LEARN_TO_SWIM_REGISTRATION}
            editElement={<LearnToSwimRegistration />} />
        }
      />
      <Route path={navRoutes.WORKFLOWS_LEARN_TO_SWIM_REGISTRATION_APPROVALS?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.WORKFLOWS_LEARN_TO_SWIM_REGISTRATION_APPROVALS}
            editElement={<LearnToSwimRegistrationApproval />} />
        }
      />

      <Route path={'/'} element={<Navigate to="/workflows/queuelist" replace />} />
    </Routes>
  );
};

const WorkFlows = () => (
  <ProgramLevelContextProvider>
    <CategoryMeasureScoreContextProvider>
      <OrgGroupPropertiesContextProvider>
        <LscContextProvider>
          <HqMemberClubTransferFilterContextProvider>
            <GroupScholasticAllAmericanFiltersContextProvider>
              <ManageWorkFlows />
            </GroupScholasticAllAmericanFiltersContextProvider>
          </HqMemberClubTransferFilterContextProvider>
        </LscContextProvider>
      </OrgGroupPropertiesContextProvider>
    </CategoryMeasureScoreContextProvider>
  </ProgramLevelContextProvider>
);

export default WorkFlows;