/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import useMeetSanctionApproval from './UseMeetSanctionApproval';

import WorkFlowNavigation from '../components/navigation/WorkFlowNavigation';
import LocalConstants from '../../../Constants';

import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import ReadOnly from '../../../../common/components/readOnly/ReadOnly';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';
import Input from '../../../../common/components/inputs/Input';

import { formatDate } from '../../../../common/utils/DateFunctions';

const MeetSanctionApproval = () => {
  const {
    Constants,
    isLoading,
    isSaving,
    hasMeetAnnouncement,
    meetSanctionState,
    meetSancReqObjState,
    onBackClicked,
    onApprovedClicked,
    onRejectClicked,
    onDownloadUploadedFile,
    formState,
    errorState,
    onFormValueChanged
  } = useMeetSanctionApproval();

  return (
    <div className="row">
      <WorkFlowNavigation headerTitle={"Meet Sanction Request"}>
        {meetSanctionState.isObjLoaded === true &&
          <form noValidate>
            <div className="row">
              <div className="col-xs-12 col-sm-6 col-sm-push-6">
                <ActionIntraPageButton className="pull-right"
                  type="button"
                  onClick={() => { }}>
                  {LocalConstants.WORKFLOW_APPLICATION_TEXT}
                </ActionIntraPageButton>
                {hasMeetAnnouncement === true &&
                  <ActionIntraPageButton type="button" className="pull-right" onClick={onDownloadUploadedFile}>
                    Download Meet Announcement
                  </ActionIntraPageButton>
                }
              </div>
              <div className="col-xs-12 col-sm-6 col-sm-pull-6">
                <ReadOnly
                  label="LSC - Club Name/Club Code: "
                  name="lscClubNameCode"
                  value={`${meetSancReqObjState.lscCode || ''} - ${meetSancReqObjState.clubName || ''}/${meetSancReqObjState.clubCode || ''}`} />
              </div>
              <div className="col-xs-12">
                <ReadOnly
                  label="Submission Date: "
                  name="submissionDate"
                  value={formatDate(meetSancReqObjState.submissionDate)} />
              </div>
            </div>
            <div className="row usas-extra-top-margin">
              <div className="col-xs-12 col-sm-6">
                <ReadOnly
                  label="Meet Name: "
                  name="meetName"
                  value={meetSancReqObjState.meetName} />
              </div>
              <div className="col-xs-12 col-sm-6">
                <ReadOnly
                  label="Meet Dates: "
                  name="meetDates"
                  value={`${formatDate(meetSancReqObjState.meetStartDate)} - ${formatDate(meetSancReqObjState.meetEndDate)}`} />
              </div>
            </div>
            <div className="row usas-extra-top-margin">
              <div className="col-xs-12 col-sm-6">
                <ReadOnly
                  label="Meet Classification: "
                  name="meetClassification"
                  value={meetSancReqObjState.meetClassification} />
              </div>
              <div className="col-xs-12 col-sm-6">
                <ReadOnly
                  label="Meet Type: "
                  name="meetType"
                  value={meetSancReqObjState.meetType} />
              </div>
            </div>
            <div className="row usas-extra-top-margin">
              <div className="col-xs-12 col-sm-6">
                <Input
                  label="Sanction Number*"
                  value={formState.meetSanctionNumber}
                  error={errorState.meetSanctionNumber}
                  message={errorState.meetSanctionNumber}
                  name='meetSanctionNumber'
                  onChange={value => onFormValueChanged('meetSanctionNumber', value)} />
              </div>
            </div>
            <div className="row usas-extra-top-margin usas-extra-bottom-margin">
              <div className="col-xs-12 usas-extra-top-margin">
                <PrimaryButton type="button" onClick={onApprovedClicked}>Approve</PrimaryButton>&nbsp;
                <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>&nbsp;
                <PrimaryButton type="button" onClick={onRejectClicked}>Reject</PrimaryButton>
              </div>
            </div>
          </form>
        }
      </WorkFlowNavigation>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </div>
  );
};

export default MeetSanctionApproval;