import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Navigate } from '../../../common/wrappers/ReactRouterDom';

import CommunicationsHubContextProvider from '../../state/communicationsHub/CommunicationsHubContextProvider';

import CommunicationsHubContextView from './CommunicationsHubContextView';
import CommunicationsHubInbox from './communicationsHubInbox/CommunicationsHubInbox';
import CommunicationsHubSettings from './communicationsHubSettings/CommunicationsHubSettings';

import SecuredRouteElement from '../../../common/components/security/SecuredRouteElement';
import LoadingModal from '../../../common/components/dialogs/LoadingModal';

import useNavRoutes from '../../../common/state/security/UseNavRoutes';

const CommunicationsHubContextRoutes = ({ navRoutes }) => (
  <CommunicationsHubContextView>
    <Routes>
      <Route path={navRoutes['COMMUNICATIONS_HUB_INBOX']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['COMMUNICATIONS_HUB_INBOX']}
            editElement={<CommunicationsHubInbox />} />
        }
      />
      <Route path={navRoutes['COMMUNICATIONS_HUB_SETTINGS']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['COMMUNICATIONS_HUB_SETTINGS']}
            editElement={<CommunicationsHubSettings />} />
        }
      />
    </Routes>
  </CommunicationsHubContextView>
);

const CommunicationsHubRoutes = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return (
    <Routes>
      <Route path={'/*'} element={<CommunicationsHubContextRoutes navRoutes={navRoutes} />} />
      <Route path={'/'} element={<Navigate to={navRoutes['COMMUNICATIONS_HUB_INBOX']?.route} replace />} />
    </Routes>
  );
};

const CommunicationsHub = () => (
  <CommunicationsHubContextProvider>
    <CommunicationsHubRoutes />
  </CommunicationsHubContextProvider>
);

export default CommunicationsHub;