import React, { Fragment } from 'react';

import EditIcon from '../../../../../../common/components/icons/EditIcon';

import { formatDate } from '../../../../../../common/utils/DateFunctions';

import global from '../../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ clubReg, onRegistrationClicked }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}>
      <span className='hidden-xs'>{`${clubReg.lscCode || ''} - ${clubReg.clubName || ''}/${clubReg.clubCode || ''}`}</span>&nbsp;
      <button
        className={global.IconButton}
        type="button"
        onClick={e => onRegistrationClicked(e, clubReg)}>
        <EditIcon />
      </button>
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={[global.SmallTableRowLabels, 'col-xs-12 visible-xs'].join(' ')}>LSC - Club Name/Club Code</div>
        <div className={[global.SmallTableRowData, 'col-xs-12 visible-xs'].join(' ')}>{`${clubReg.lscCode || ''} - ${clubReg.clubName || ''}/${clubReg.clubCode || ''}`}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Submission Date</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{formatDate(clubReg.submissionDate)}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Registration Type</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{clubReg.offeringName}</div>
      </div>
    </div>
  </div>
);

const SmallGrid = ({ data, isLoading, onRegistrationClicked }) => (
  <Fragment>
    <div className={[global.SmallTable, 'hidden-lg hidden-md'].join(' ')}>
      {isLoading === true
        ? (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>Loading...</div></div>
          </div>
        )
        : data.length > 0
          ? data.map((clubReg, i) => <GridRow key={i} clubReg={clubReg} onRegistrationClicked={onRegistrationClicked} />)
          : (
            <div className={global.SmallTableRow}>
              <div className={global.SmallTableRowHead}>&nbsp;</div>
              <div className={global.SmallTableRowInfo}>
                <div className={global.SmallTableRowLabels}>No Club Registrations</div>
              </div>
            </div>
          )
      }
    </div>
  </Fragment>
);

export default SmallGrid;