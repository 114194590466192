/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import SGColumnHeader from '../../../../../../common/components/grids/SGColumnHeader';

import useSortableGrid from '../../../../../../common/utils/UseSortableGrid';
import SGConstants from '../../../../../../common/utils/SGConstants';

import global from '../../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ groupInvite, onGroupInviteClicked }) => (
  <tr>
    <td><a onClick={(e) => onGroupInviteClicked(e, groupInvite)}>
      {groupInvite.firstName || ''} {groupInvite.lastName || ''}
    </a></td>
    <td>{groupInvite.groupName || ''}</td>
  </tr>
);

const LargeGrid = ({ data, isLoading, onGroupInviteClicked }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(data);

  return (
    <table className={[global.UsasTable, 'visible-lg visible-md'].join(' ')}>
      <thead>
        <tr>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Name'} columnField={'lastName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Group'} columnField={'groupName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
        </tr>
      </thead>
      <tbody>
        {isLoading === true
          ? <tr><td colSpan="2">Loading...</td></tr>
          : sortableGridState.sortedGridData.length > 0
            ? sortableGridState.sortedGridData.map((groupInvite, i) => <GridRow key={i} groupInvite={groupInvite} onGroupInviteClicked={onGroupInviteClicked} />)
            : <tr><td colSpan="2">No Group Invites</td></tr>
        }
      </tbody>
    </table>
  );
};

export default LargeGrid;