import React, { Fragment } from 'react';

import useClubRecognitionCategoryMeasureApprovals from './UseClubRecognitionCategoryMeasureApprovals';

import WorkFlowNavigation from '../components/navigation/WorkFlowNavigation';

import Input from '../../../../common/components/inputs/Input';
import Textarea from '../../../../common/components/inputs/Textarea';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import LeftArrowIcon from '../../../../common/components/icons/LeftArrowIcon';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import global from '../../../../common/components/GlobalStyle.module.css';

const Question = ({ question }) => (
  <div dangerouslySetInnerHTML={{ __html: question }} />
);

const ClubRecognitionCategoryMeasureApprovals = () => {
  const {
    Constants,
    state,
    categoryMeasureScoreState,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onCancelClicked,
    onBackClicked,
    onDownloadUploadedFile,
  } = useClubRecognitionCategoryMeasureApprovals();

  return (
    <div className="row">
      <WorkFlowNavigation headerTitle={'Club Recognition Program - Approve Task'}>
        <div className="row">
          <div className="col-xs-12">
            <button className={global.BackToSearchButton}
              type="button"
              onClick={onBackClicked}>
              <LeftArrowIcon /> Back to Safe Sport Recognition Program Queue
            </button>
          </div>
        </div>
        <div className="row">
          <div className={["col-xs-12", global.HeaderText].join(' ')}>
            <p><b>LSC Code/Club Code:</b> {state.qualifiedOrgUnitCode}</p>
            <p><b>Club Name:</b> {state.clubName}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <hr className={[global.ThinBar, 'usas-bar-gray'].join(' ')} />
          </div>
        </div>
        <div className="row">
          <div className={["col-xs-12", global.HeaderText].join(' ')}>
            <p><b>Level:</b> {state.programLevel?.programLevelName}</p>
            <p><b>Task Name:</b> {state.categoryMeasure?.measureName}</p>
            <p><Question question={'<b>Question:</b> ' + state.categoryMeasure?.question} /></p>
            <p><b>Point Range:</b> {state.categoryMeasure?.minValue?.toString()} {state.categoryMeasure?.minValue !== state.categoryMeasure?.maxValue ? '- ' + state.categoryMeasure?.maxValue?.toString() : ''}</p>
            <p><b>Submission Date:</b> {state.submissionDate}</p>
            {state.isFileUpload === true ?
              <Fragment>
                <PrimaryButton type="button" onClick={(event) => onDownloadUploadedFile(event)}>{'Download Uploaded File'}</PrimaryButton>
              </Fragment>
              :
              <p><b>Submitted Value:</b> {state.categoryMeasure?.submittedValue}</p>}
          </div>
        </div>
        <form onSubmit={handleSubmit} noValidate>
          <div className="row usas-extra-top-margin">
            <div className="col-xs-12 col-sm-4">
              <Input
                label="Score*"
                name="score"
                type="number"
                min="0"
                value={formState.score}
                error={errorState.score}
                message={errorState.score}
                onChange={(value) => { onFormValueChanged('score', value); }} />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <Textarea
                className={global.Textarea}
                label="Notes"
                name="notes"
                value={formState.notes}
                error={errorState.notes}
                message={errorState.notes}
                onChange={value => onFormValueChanged('notes', value)} />
            </div>
          </div>
          <div className="row usas-extra-top-margin usas-extra-bottom-margin">
            <div className="col-xs-12">
              <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
              <SecondaryButton type="button" onClick={onCancelClicked}>Cancel</SecondaryButton>
            </div>
          </div>
        </form>
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.LOADING_MSG}
          displayPopUp={categoryMeasureScoreState.isObjLoading} />
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.SAVING_MSG}
          displayPopUp={categoryMeasureScoreState.isSaving} />
      </WorkFlowNavigation>
    </div>
  );
};

export default ClubRecognitionCategoryMeasureApprovals;