import React, { Fragment } from 'react';

import GroupSAAInviteGridLarge from './GroupSAAInviteGridLarge';
import GroupSAAInviteGridSmall from './GroupSAAInviteGridSmall';

const GroupSAAInviteGrid = ({data, isLoading, onGroupInviteClicked}) => (
  <Fragment>
    <GroupSAAInviteGridLarge data={data} isLoading={isLoading} onGroupInviteClicked={onGroupInviteClicked}/>
    <GroupSAAInviteGridSmall data={data} isLoading={isLoading} onGroupInviteClicked={onGroupInviteClicked}/>
  </Fragment>
);

export default GroupSAAInviteGrid;