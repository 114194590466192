import { isValidDate } from '../../../../common/utils/validation';
import Constants from "../../../../common/utils/Constants";

const TimeBatchProcessTimeRecognitionsValidation = async (formState) => {
  let errors = {};

  const startDate = new Date(formState.startDate);
  const endDate = new Date(formState.endDate);

  if (formState.startDate === Constants.BLANK_DATE_STRING) {
    errors.startDate = 'Start Date is required';
  }
  if (formState.endDate === Constants.BLANK_DATE_STRING) {
    errors.endDate = 'End Date is required';
  }
  if (formState.endDate === Constants.BLANK_DATE_STRING && formState.startDate !== Constants.BLANK_DATE_STRING) {
    errors.endDate = 'End Date is needed if the Start Date is specified';
  }
  if (endDate < startDate) {
    errors.endDate = 'End Date must be after the Start Date'
  }
  if (!isValidDate(formState.startDate) && formState.startDate !== Constants.BLANK_DATE_STRING) {
    errors.startDate = 'Start Date must be a valid date';
  }
  if (!isValidDate(formState.endDate) && formState.endDate !== Constants.BLANK_DATE_STRING) {
    errors.endDate = 'End Date must be a valid date';
  }

  return errors ? errors : {};
};

export default TimeBatchProcessTimeRecognitionsValidation;
