import { Fragment } from "react";

import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from "../../../../common/components/dialogs/PopUpModal";
import Headings from "../../../../common/components/headings/Headings";
import Constants from "../../../../common/utils/Constants";
import global from '../../../../common/components/GlobalStyle.module.css';

import useTimeBatchProcessTimeRecords from "./UseTimeBatchProcessTimeRecords";

const getColorForMessage = (type) => {
  if (type === "individual") {
    return 'lightblue'
  } else if (type === "relay") {
    return 'lightgrey'
  } else return 'ivory'
}

const GridRow = ({ log }) => (
  <tr>
    <td style={{backgroundColor: getColorForMessage(log.logType)}}>{log.message}</td>
  </tr>
);

const TimeBatchProcessTimeRecords = () => {
  const { 
    timeBatchProcessRunLogState, 
    isLoading,
    allowProcessing,
    allowClear,
    allowRefresh,
    onClickProcess,
    onClickClearResults,
    onClickRefresh
  } = useTimeBatchProcessTimeRecords();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Process Records</Headings.H3>
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12" style={{fontSize: 18}}>
          {allowProcessing === true &&
            <PrimaryButton type='button' onClick={onClickProcess}>Process Records</PrimaryButton>
          }
          {allowClear === true &&
            <SecondaryButton type='button' onClick={onClickClearResults}>Clear Results</SecondaryButton>  
          }
          {allowRefresh === true &&
            <div style={{fontSize: 21, color: "#444444"}}>
              <>
              This can take over 5 minutes to complete. 
              After starting the process, you can work on other tasks and return later to see the results.
              Or, stay here and click the 'REFRESH' button.
              </><br/><br/>
              <SecondaryButton type='button' onClick={onClickRefresh}>Refresh</SecondaryButton>
            </div>
          }
        </div>
      </div>

      <table className={[global.UsasTable, 'visible-xs visible-sm visible-md visible-lg'].join(' ')}>
        <tbody>
        {isLoading === true
          ? (
            <tr>
              <td colSpan="2">Processing...</td>
            </tr>
          ) : Array.isArray(timeBatchProcessRunLogState.arrayData) && timeBatchProcessRunLogState.arrayData.length > 0
            ?
            timeBatchProcessRunLogState.arrayData.map((log, i) => (
              <GridRow
                key={i}
                log={log} />
            )) : (
              <tr>
                <td colSpan="2"></td>
              </tr>
            )
        }
        </tbody>
      </table>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.PROCESSING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  )
};

export default TimeBatchProcessTimeRecords;