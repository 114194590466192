import { useEffect } from 'react';
import { useNavigate } from '../../../common/wrappers/ReactRouterDom';

import useSecurityData from '../../../common/state/security/UseSecurityData';
import Constants from '../../../common/utils/Constants';
import navLinks from './NavLinks';

const TAXONOMIES = ['CommunicationsHub'];
const SCOPE = 'CommunicationsHub';

const useCommunicationsHubContextView = () => {
  const navigate = useNavigate();
  const { securityState, getContextSecurity } = useSecurityData();

  const onHomeClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    navigate(navLinks.COMMUNICATIONS_HUB_INBOX);
  };

  useEffect(() => {
    //TODO: set context security to the logged in user's Id
    getContextSecurity(Constants.DEFAULT_POSITIVE_ID, TAXONOMIES, SCOPE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [securityState]);

  return {
    onHomeClicked
  };
};

export default useCommunicationsHubContextView;