import { useEffect } from 'react';

import useTimeBatchProcessData from '../../../state/timeBatchProcess/UseTimeBatchProcessData';

const useTimeBatchProcessTimeRecords = () => {
  const { 
    timeBatchProcessRunLogState, 
    calculateTimeRecords, 
    getCalculateTimeRecordsRunLog, 
    deleteTimeRecordsRunLog
  } = useTimeBatchProcessData();

  const onClickProcess = () => {
    calculateTimeRecords();
  };

  const onClickClearResults = () => {
    deleteTimeRecordsRunLog("records");
  }

  const onClickRefresh = () => {
    getCalculateTimeRecordsRunLog("records");
  }

  useEffect(() => {
    getCalculateTimeRecordsRunLog("records");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    timeBatchProcessRunLogState,
    isLoading: timeBatchProcessRunLogState.isArrayLoading,
    allowProcessing: timeBatchProcessRunLogState.arrayData.length === 0,
    allowClear: timeBatchProcessRunLogState.arrayData.length > 1,
    allowRefresh: timeBatchProcessRunLogState.arrayData.length === 1,
    onClickProcess,
    onClickClearResults,
    onClickRefresh
  };
};

export default useTimeBatchProcessTimeRecords;