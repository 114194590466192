import { RecHttpHelper, NO_DATA_MESSAGE } from '../../../common/utils/HttpHelper';

const PostProgramLevelInitiationData = (orgUnitId, programTypeId, state, setState) => {
  if (state.isSaving !== true && !state.message) {
    let newState = {
      ...state,
      isSaving: true,
      isSaved: false,
      message: 'Saving...'
    };
    setState(newState);

    const orgUnitIdForUrl = orgUnitId ? encodeURIComponent(orgUnitId) : 'NaN';
    const programTypeIdForUrl = programTypeId ? encodeURIComponent(programTypeId) : 'NaN';

    const url = `/ProgramLevelInstance/Initiate?orgUnitId=${orgUnitIdForUrl}&programTypeId=${programTypeIdForUrl}`;
    RecHttpHelper(url, 'POST')
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          setState({
            ...newState,
            isSaving: false,
            isSaved: true,
            isArrayLoaded: true,
            arrayData,
            message: ''
          });
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isSaving: false,
          isSaved: false,
          isArrayLoaded: false,
          message: message
        });
      });
  };
};

export default PostProgramLevelInitiationData;