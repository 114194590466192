import React from 'react';

import useSafeSport from './UseSafeSport';

import WorkFlowNavigation from '../components/navigation/WorkFlowNavigation';
import ProgramLevelClubGrid from '../components/grids/programLevelClubGrid/ProgramLevelClubGrid';

import OrgUnitCombobox from '../../../../common/components/comboboxes/orgUnitCombobox/OrgUnitCombobox';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const SafeSport = () => {
  const {
    isArrayLoading,
    filteredArrayData,
    formState,
    errorState,
    USAS_ORGANIZATION_ID,
    onEdit,
    onValueTextPairChanged,
    handleSubmit,
    onClearFilterClicked
  } = useSafeSport();

  return (
    <div className="row">
      <WorkFlowNavigation headerTitle={"Safe Sport Recognition Program Queue"}>
        <form onSubmit={handleSubmit} noValidate>
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              <OrgUnitCombobox
                label={formState.orgUnitLabel}
                name="orgUnitName"
                valueToMatch={formState.orgUnitName}
                error={errorState.orgUnitId}
                message={errorState.orgUnitId}
                onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'orgUnitId', newValueLabel, 'orgUnitName', e); }}
                organizationId={USAS_ORGANIZATION_ID || Constants.DEFAULT_ID}
                lscId={Constants.DEFAULT_ID} />
            </div>
            <div className="col-xs-4 col-sm-2">
              <button type="submit" className={global.PlainButton}>Filter</button>
            </div>
            <div className="col-xs-8 col-sm-4">
              <button type="button" onClick={(e) => onClearFilterClicked(e)} className={global.PlainButton}>Clear Filter</button>
            </div>
          </div>
        </form>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12">
            <ProgramLevelClubGrid
              showSearchMsg={formState.orgUnitId ? true : false}
              data={filteredArrayData}
              isLoading={isArrayLoading}
              onEdit={onEdit} />
          </div>
        </div>
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.LOADING_MSG}
          displayPopUp={isArrayLoading} />
      </WorkFlowNavigation>
    </div>
  );
};

export default SafeSport;