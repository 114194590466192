import React, { Fragment } from 'react';

import LargeGrid from './LargeGrid';
import SmallGrid from './SmallGrid';

const ProgramLevelClubGrid = ({ showSearchMsg, data, isLoading, onEdit }) => (
  <Fragment>
    <LargeGrid showSearchMsg={showSearchMsg} data={data} isLoading={isLoading} onEdit={onEdit} />
    <SmallGrid showSearchMsg={showSearchMsg} data={data} isLoading={isLoading} onEdit={onEdit} />
  </Fragment>
);

export default ProgramLevelClubGrid;