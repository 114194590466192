import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import { createNewWorkflowObj } from '../utils/WorkflowsUtils';

import { navLinks } from '../../../UseNavLinks';

import useSafeSportData from '../../../state/workflow/safeSport/UseSafeSportData';

import useProgramLevelData from '../../../../common/state/programLevel/UseProgramLevelData';

import Constants from '../../../../common/utils/Constants';
import { formatDate } from '../../../../common/utils/DateFunctions';

const useSafeSportProgramLevelApprovals = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { safeSportState, getSafeSportWorkflow, putSafeSportWorkflow } = useSafeSportData();
  const { programLevelState } = useProgramLevelData();
  const [state, setState] = useState({
    programLevel: {}, qualifiedOrgUnitCode: '',
    clubName: '', submissionDate: '', error: '', tryRedirect: false,
    allowApproval: true, namesOfProblemLevelOrCategory: ''
  });
  const [displayModalState, setDisplayModalState] = useState(false);

  const onBackClicked = () => {
    navigate(navLinks.SAFE_SPORT_REQUEST);
  };

  const onApproveClicked = () => {
    if (state.allowApproval === false) {
      setState({ ...state, error: `Unable to Approve. ${state.namesOfProblemLevelOrCategory} Current Points must be greater than or equal to the Minimum Points.` })
    }
    else {
      const workflowObjCopy = JSON.parse(JSON.stringify(safeSportState.objData));
      putSafeSportWorkflow(state.programLevel?.workflowId,
        createNewWorkflowObj(workflowObjCopy, state.programLevel?.workflowStepId, Constants.WORKFLOW_STATUS_APPROVED,
          '', 0));
      setState({ ...state, tryRedirect: true });
    }
  };

  const onCancelClicked = () => {
    navigate(navLinks.SAFE_SPORT_PROGRAM_LEVEL, { state: { programLevel: state.programLevel } });
  };

  const onSendBackClicked = () => {
    setDisplayModalState(true);
  };

  const onModalCancelClicked = () => {
    setDisplayModalState(false);
  };

  const onSubmitFormCallback = (formState) => {
    const workflowObjCopy = JSON.parse(JSON.stringify(safeSportState.objData));
    putSafeSportWorkflow(state.programLevel?.workflowId,
      createNewWorkflowObj(workflowObjCopy, state.programLevel?.workflowStepId, Constants.WORKFLOW_STATUS_SEND_BACK,
        formState.stepNotes, 0));
    setState({ ...state, tryRedirect: true });
  };

  useEffect(() => {
    if (location.state && location.state.programLevel?.workflowId > 0) {
      const programLevel = location.state.programLevel;
      let allowApproval = true;
      let namesOfProblemLevelOrCategory = '';

      if (programLevelState.objData?.currentPointValue < programLevelState.objData?.minimumValue) {
        allowApproval = false;
        namesOfProblemLevelOrCategory = namesOfProblemLevelOrCategory === '' ?
          programLevelState.objData?.levelName :
          namesOfProblemLevelOrCategory + ', ' + programLevelState.objData?.levelName
      }
      if (Array.isArray(programLevelState.objData?.programCategory)) {
        for (let i = 0; i < programLevelState.objData?.programCategory.length; i++) {
          if (programLevelState.objData?.programCategory[i].currentPointValue <
            programLevelState.objData?.programCategory[i].minimumValue) {
            allowApproval = false;
            namesOfProblemLevelOrCategory = namesOfProblemLevelOrCategory === '' ?
              programLevelState.objData?.programCategory[i].categoryName :
              namesOfProblemLevelOrCategory + ', ' + programLevelState.objData?.programCategory[i].categoryName
          }
        }
      }

      setState({
        ...state,
        programLevel: programLevel,
        qualifiedOrgUnitCode: programLevel.qualifiedOrgUnitCode,
        clubName: programLevel.clubName,
        submissionDate: formatDate(programLevel.submissionDate),
        allowApproval: allowApproval,
        namesOfProblemLevelOrCategory: namesOfProblemLevelOrCategory
      });
      getSafeSportWorkflow(programLevel.workflowId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (state.tryRedirect === true && safeSportState.isSaved === true) {
      navigate(navLinks.SAFE_SPORT_REQUEST);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, safeSportState]);

  return {
    Constants,
    safeSportState,
    programLevelState,
    state,
    displayModalState,
    onBackClicked,
    onApproveClicked,
    onSendBackClicked,
    onCancelClicked,
    onSubmitFormCallback,
    onModalCancelClicked
  };
};

export default useSafeSportProgramLevelApprovals;