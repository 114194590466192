import React from 'react';

import useGroupNationalTeamInvite from './UseGroupNationalTeamInvite';

import WorkFlowNavigation from '../components/navigation/WorkFlowNavigation';
import GroupInviteGrid from '../components/grids/groupInviteGrid/GroupInviteGrid';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

const GroupNationalTeamInvite = () => {
  const {
    Constants,
    groupNationalTeamInviteState,
    onGroupInviteClicked
  } = useGroupNationalTeamInvite();

  return (
    <div className="row">
      <WorkFlowNavigation headerTitle={"National Team Group Invite Queue"}>
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <GroupInviteGrid
              data={groupNationalTeamInviteState.arrayData}
              isLoading={groupNationalTeamInviteState.isArrayLoading}
              onGroupInviteClicked={onGroupInviteClicked} />
          </div>
        </div>
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.LOADING_MSG}
          displayPopUp={groupNationalTeamInviteState.isArrayLoading} />
      </WorkFlowNavigation>
    </div>
  );
};

export default GroupNationalTeamInvite;