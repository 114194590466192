import { useEffect } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import UseNewClubRegistrationData from '../../../state/workflow/newClubRegistration/UseNewClubRegistrationData';

import Constants from '../../../../common/utils/Constants';

const useNewClubRegistration = (approvalsPagePath) => {
  const navigate = useNavigate();
  const { newClubRegistrationState, getNewClubRegistration } = UseNewClubRegistrationData();

  const onRegistrationClicked = (e, clubReg) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(approvalsPagePath, { state: { clubReg } });
  };

  useEffect(() => {
    if (newClubRegistrationState.isArrayLoaded !== true) {
      getNewClubRegistration();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newClubRegistrationState.isArrayLoaded]);

  return {
    Constants,
    newClubRegistrationState,
    onRegistrationClicked
  };
};

export default useNewClubRegistration;