export const isValidLink = (link) => {
  try {
    const trimmedLink = link.trim();
    const http = trimmedLink.substring(0, 7);

    if (http === 'http://' && link.length > 7) {
      return true;
    } else {
      const https = trimmedLink.substring(0, 8);

      if (https === 'https://' && link.length > 8) {
        return true;
      } else {
        return false;
      }
    }
  } catch {
    return false;
  }
};