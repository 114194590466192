export const GroupScholasticAllAmericanApprovalFormValidation = (formState) => {
  let errors = {};

  if (!formState.gpa || formState.gpa.trim() === '') {
    errors.gpa = 'Weighted GPA is required';
  }

  if (!formState.unweightedGPA || formState.unweightedGPA.trim() === '') {
    errors.unweightedGPA = 'Unweighted GPA is required';
  }

  return errors;
};

export default GroupScholasticAllAmericanApprovalFormValidation;