import UseForm from "../../../../../common/utils/UseForm";

import validate from './WorkflowStepNotesPopupValidation';

const INITIAL_FORM_STATE = {
  stepNotes: ''
};

const useWorkflowStepNotesPopup = (onSubmitFormCallback) => {
  const { formState, errorState, updateFormState, handleSubmit
  } = UseForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);

  return {
    formState,
    errorState,
    updateFormState,
    handleSubmit
  };
};

export default useWorkflowStepNotesPopup;