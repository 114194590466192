import { useEffect } from 'react';
import { useNavigate } from '../../../common/wrappers/ReactRouterDom';

import NavLinks from './NavLinks';

import useSecurityData from '../../../common/state/security/UseSecurityData';

import Constants from '../../../common/utils/Constants';

const TAXONOMIES = ['BatchProcess'];
const SCOPE = 'BatchProcess';

const useTimeBatchProcessContextView = () => {
  const navigate = useNavigate();
  const { securityState, getContextSecurity } = useSecurityData();

  const onHomeClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.ROOT);
  };

  useEffect(() => {
    //TODO: set context security to the logged in user's Id
    getContextSecurity(Constants.DEFAULT_POSITIVE_ID, TAXONOMIES, SCOPE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [securityState]);

  return {
    onHomeClicked
  };
};

export default useTimeBatchProcessContextView;