import { useEffect, useState } from 'react';
import useNavRoutes from '../../../../common/state/security/UseNavRoutes';
import useApprovalQueuesData from '../../../state/approvalQueues/UseApprovalQueuesData';

const INITIAL_VIEW_STATE = {
  loading: true,
  approvalQueues: []
};

const useApprovalQueues = () => {
  const { approvalQueuesState, getQueueStatus } = useApprovalQueuesData();
  const { navRoutes } = useNavRoutes();
  const [state, setState] = useState(INITIAL_VIEW_STATE);

  const filterQueue = (queue) => {
    const isViewable = navRoutes[queue.routeName]?.permission === 'edit';

    return isViewable;
  };

  useEffect(() => {
    if (approvalQueuesState.isArrayLoaded !== true) {
      getQueueStatus();
    } else {
      const approvalQueues = approvalQueuesState.arrayData?.filter(filterQueue);

      setState({
        loading: approvalQueuesState.isArrayLoading,
        approvalQueues: approvalQueues || []
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approvalQueuesState]);

  return { ...state };
};

export default useApprovalQueues;