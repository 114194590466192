import { useContext } from "react";

import { HqMemberClubTransferFilterStateContext } from "./HqMemberClubTransferFilterContextProvider";

const useHqMemberClubTransferFilter = () => {
  const [hqMemberClubTransferFilterState, setHqMemberClubTransferFilterState] = useContext(HqMemberClubTransferFilterStateContext);

  const updateFilters = (orgUnitId, orgUnitName) => {
    setHqMemberClubTransferFilterState({
      ...hqMemberClubTransferFilterState,
      orgUnitId,
      orgUnitName
    });
  };

  return {
    hqMemberClubTransferFilterState,
    updateFilters
  };
};

export default useHqMemberClubTransferFilter;