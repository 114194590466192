import { useEffect } from 'react';

import useCommunicationsHubData from '../../../state/communicationsHub/UseCommunicationsHubData';

import localConstants from '../../../Constants';

import useSecurityData from '../../../../common/state/security/UseSecurityData';

const UseCommunicationsHubInbox = () => {
  const { messageState, setMessageState, getMessagesData, putMessageData, putCustomMessageData,
    deleteMessageData, deleteCustomMessageData } = useCommunicationsHubData();
  const { userInfo } = useSecurityData();

  const onDeleteClick = (message) => {
    if (message.personInboxId) {
      deleteMessageData(message.personInboxId);
    }
    else if (message.personCustomInboxId) {
      deleteCustomMessageData(message.personCustomInboxId);
    }
  };

  const onReadMessage = (message) => {
    if (message.status !== localConstants.MESSAGE_READ_STATUS_TEXT) {
      const newMessageState = { ...messageState };
      const newMessage = { ...message };
      let messageIndex = -1;
      if (message.personInboxId) {
        messageIndex = newMessageState.arrayData.findIndex(x => x.personInboxId === newMessage.personInboxId);
      }
      else if (message.personCustomInboxId) {
        messageIndex = newMessageState.arrayData.findIndex(x => x.personCustomInboxId === newMessage.personCustomInboxId);
      }
      newMessage.status = localConstants.MESSAGE_READ_STATUS_TEXT;
      newMessage.unreadMessage = false;
      newMessageState.arrayData[messageIndex] = newMessage;
      setMessageState(newMessageState);
      if (message.personInboxId) {
        putMessageData(newMessage.personInboxId, newMessage);
      }
      else if (message.personCustomInboxId) {
        putCustomMessageData(newMessage.personCustomInboxId, newMessage);
      }
    }
  };

  useEffect(() => {
    if (userInfo?.isLoggedIn === true
      && messageState.isArrayLoaded === false
      && messageState.isArrayLoading === false) {
      getMessagesData(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.isLoggedIn, messageState.isArrayLoaded, messageState.isArrayLoading]);

  useEffect(() => {
    if (userInfo?.isLoggedIn === true
      && messageState.isObjLoaded === true
      && messageState.isObjLoading === false) {
      getMessagesData(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.isLoggedIn, messageState.isObjLoaded, messageState.isObjLoading]);

  return {
    messageState,
    onDeleteClick,
    onReadMessage
  };
};

export default UseCommunicationsHubInbox;