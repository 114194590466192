/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import SGColumnHeader from '../../../../../../common/components/grids/SGColumnHeader';

import { formatDate } from '../../../../../../common/utils/DateFunctions';
import useSortableGrid from '../../../../../../common/utils/UseSortableGrid';
import SGConstants from '../../../../../../common/utils/SGConstants';

import global from '../../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ programLevel, onEdit }) => (
  <tr>
    <td><a onClick={e => onEdit(e, programLevel)}>{programLevel.qualifiedOrgUnitCode}</a></td>
    <td>{programLevel.clubName}</td>
    <td>{programLevel.programLevelName}</td>
    <td>{formatDate(programLevel.submissionDate)}</td>
  </tr>
);

const LargeGrid = ({ showSearchMsg, data, isLoading, onEdit }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(data);

  return (
    <table className={[global.UsasTable, 'visible-lg visible-md'].join(' ')}>
      <thead>
        <tr>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'LSC / Club Code'} columnField={'qualifiedOrgUnitCode'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Club Name'} columnField={'clubName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Program Level'} columnField={'programLevelName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Submission Date'} columnField={'submissionDate'}
            sortType={SGConstants.SORT_TYPE_DATE} onColumnClicked={onColumnClicked} />
        </tr>
      </thead>
      <tbody>
        {isLoading === true
          ? <tr><td colSpan="4">Loading...</td></tr>
          : sortableGridState.sortedGridData.length > 0
            ? sortableGridState.sortedGridData.map((programLevel, i) => <GridRow key={i} programLevel={programLevel} onEdit={onEdit} />)
            : <tr><td colSpan="4">{showSearchMsg === true ? 'No Search Results' : 'No Submitted Program Levels'}</td></tr>
        }
      </tbody>
    </table>
  );
};

export default LargeGrid;