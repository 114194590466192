import { BASIC_INITIAL_STATE } from '../../utils/HttpHelper';

import getReportCategoryData from './GetReportCategoryData';
import getReportForLscData from './GetReportForLscData';
import getReportForClubData from './GetReportForClubData';
import getReportForAdminData from './GetReportForAdminData';
import getReportsForCurrentUserData from './GetReportsForCurrentUserData';
import getReportData from './GetReportData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const PowerBiReportsData = {
  INITIAL_STATE,
  getReportCategoryData,
  getReportsForCurrentUserData,
  getReportData,
  getReportForLscData,
  getReportForAdminData,
  getReportForClubData
};

export default PowerBiReportsData;