import React, { Fragment } from 'react';

import ReadOnly from '../../../../../../common/components/readOnly/ReadOnly';

const GroupNationalTeamApprovalForm = ({ formState }) => (
  <Fragment>
    <div className="row">
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Person Name:"
          name="personName"
          value={formState.personName} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Group Name:"
          name="groupName"
          value={formState.groupName} />
      </div>
    </div>
    <div className="row">
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Attendee Type:"
          name="groupAttendeeTypeName"
          value={formState.groupAttendeeTypeName} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Role:"
          name="groupRoleTypeName"
          value={formState.groupRoleTypeName} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Professional Swimmer:"
          name="professionalSwimmer"
          value={formState.professionalSwimmer} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="City of Birth:"
          name="cityOfBirth"
          value={formState.cityOfBirth} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Country of Birth:"
          name="countryOfBirth"
          value={formState.countryOfBirth} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Fan Mail Address:"
          name="fanMailAddress"
          value={formState.fanMailAddress} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Fan Mail City:"
          name="fanMailCity"
          value={formState.fanMailCity} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Fan Mail State:"
          name="fanMailStateCode"
          value={formState.fanMailStateCode} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Fan Mail Zip Code:"
          name="fanMailZip"
          value={formState.fanMailZip} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Passport Number:"
          name="passportNumber"
          value={formState.passportNumber} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Passport Effective Date:"
          name="passportEffectiveDate"
          value={formState.passportEffectiveDate} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Passport Expiration Date:"
          name="passportExpirationDate"
          value={formState.passportExpirationDate} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Known Traveler Number:"
          name="knownTravelerNumber"
          value={formState.knownTravelerNumber} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Preferred Airline:"
          name="preferredAirlineName"
          value={formState.preferredAirlineName} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Aisle/Window Preference:"
          name="aisleWindowPreferenceName"
          value={formState.aisleWindowPreferenceName} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Airline Status:"
          name="airlineStatus"
          value={formState.airlineStatus} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Primary Health Provider:"
          name="primaryHealthProvider"
          value={formState.primaryHealthProvider} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Agent:"
          name="agent"
          value={formState.agent} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="High School Name:"
          name="highSchoolName"
          value={formState.highSchoolName} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="High School Graduation Year:"
          name="highSchoolGraduationYear"
          value={formState.highSchoolGraduationYear} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="College Name:"
          name="collegeName"
          value={formState.collegeName} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="College Graduation Year:"
          name="collegeGraduationYear"
          value={formState.collegeGraduationYear} />
      </div>
    </div>
  </Fragment>
);

export default GroupNationalTeamApprovalForm;