import React from 'react';

import EditIcon from '../../../../../../common/components/icons/EditIcon';

import { formatDate } from '../../../../../../common/utils/DateFunctions';

import global from '../../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ transferRequest, onRequestClicked }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}>
      <span className='hidden-xs'>{transferRequest.firstName} {transferRequest.lastName}</span>&nbsp;
      <button
        className={global.IconButton}
        type="button"
        onClick={(e) => onRequestClicked(e, transferRequest.workflowId)}>
        <EditIcon />
      </button>
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Name</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>{transferRequest.firstName} {transferRequest.lastName}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Request Date</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{formatDate(transferRequest.transferRequestDate)}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>From Club</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{transferRequest.transferFromClubName}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>To Club</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{transferRequest.transferToClubName}</div>
      </div>
    </div>
  </div>
);

const SmallGrid = ({ data, isLoading, onRequestClicked }) => (
  <div className={[global.SmallTable, 'hidden-lg hidden-md'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>Loading...</div>
          </div>
        </div>
      )
      : data.length > 0
        ? data.map((transferRequest, i) => <GridRow key={i} transferRequest={transferRequest} onRequestClicked={onRequestClicked} />)
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Club Transfer Requests</div>
            </div>
          </div>
        )
    }
  </div>
);

export default SmallGrid;