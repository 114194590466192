import React from 'react';

import useApprovalQueues from './UseApprovalQueues';

import LoadingModal from '../../../../common/components/dialogs/LoadingModal';
import Headings from '../../../../common/components/headings/Headings';
import Grid from './Grid';

import global from '../../../../common/components/GlobalStyle.module.css';

const ApprovalQueues = () => {
  const { loading, approvalQueues } = useApprovalQueues();

  return (
    <div className="row">
      <div className="col-xs-12">
        <Headings.H3
          className={[global.NoBottomMargin, 'col-xs-12'].join(' ')}
          excludeBar={true}>
          Administrative Approvals
        </Headings.H3>
      </div>
      <div className="col-xs-12">
        <hr className="usas-bar-turmeric" />
      </div>
      <div className="col-xs-12">
        {loading === true ? <LoadingModal /> : <Grid approvalQueues={approvalQueues} />}
      </div>
    </div>
  );
};

export default ApprovalQueues;