import { useState } from 'react';

import GroupScholasticAllAmericanInviteData from './GroupScholasticAllAmericanInviteData';

const useGroupScholasticAllAmericanInviteData = () => {
  const [groupScholasticAllAmericanInviteState, setGroupScholasticAllAmericanInviteState] = useState(GroupScholasticAllAmericanInviteData.INITIAL_STATE);

  const getGroupScholasticAllAmericanInvites = () => {
    GroupScholasticAllAmericanInviteData.getGroupScholasticAllAmericanInvitesData(groupScholasticAllAmericanInviteState, setGroupScholasticAllAmericanInviteState);
  };

  const getGroupScholasticAllAmericanInviteWorkflow = (workflowId) => {
    GroupScholasticAllAmericanInviteData.getGroupScholasticAllAmericanInviteWorkflowData(workflowId, groupScholasticAllAmericanInviteState, setGroupScholasticAllAmericanInviteState);
  };

  const putGroupScholasticAllAmericanInviteWorkflow = (workflowId, workflowObj) => {
    GroupScholasticAllAmericanInviteData.putGroupScholasticAllAmericanInviteWorkflowData(workflowId, workflowObj, groupScholasticAllAmericanInviteState, setGroupScholasticAllAmericanInviteState);
  };

  return {
    groupScholasticAllAmericanInviteState,
    getGroupScholasticAllAmericanInvites,
    getGroupScholasticAllAmericanInviteWorkflow,
    putGroupScholasticAllAmericanInviteWorkflow
  };
};

export default useGroupScholasticAllAmericanInviteData;