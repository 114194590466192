import React, { Fragment } from 'react';

import EditIcon from '../../../../../../common/components/icons/EditIcon';

import { formatDate } from '../../../../../../common/utils/DateFunctions';

import global from '../../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ programLevel, onEdit }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}>
      <span className='hidden-xs'>{programLevel.qualifiedOrgUnitCode}</span>&nbsp;
      <button
        className={global.IconButton}
        type="button"
        onClick={e => onEdit(e, programLevel)}>
        <EditIcon />
      </button>
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>LSC / Club Code</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>{programLevel.qualifiedOrgUnitCode || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Club Name</div>
        <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{programLevel.clubName || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Program Level</div>
        <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{programLevel.programLevelName || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Submission Date</div>
        <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{formatDate(programLevel.submissionDate)}</div>
      </div>
    </div>
  </div>
);

const SmallGrid = ({ showSearchMsg, data, isLoading, onEdit }) => (
  <Fragment>
    <div className={[global.SmallTable, 'hidden-lg hidden-md'].join(' ')}>
      {isLoading === true
        ? (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}><div className={global.SmallTableRowLabels}>Loading...</div></div>
          </div>
        )
        : data.length > 0
          ? data.map((programLevel, i) => <GridRow key={i} programLevel={programLevel} onEdit={onEdit} />)
          : (
            <div className={global.SmallTableRow}>
              <div className={global.SmallTableRowHead}>&nbsp;</div>
              <div className={global.SmallTableRowInfo}><div className={global.SmallTableRowLabels}>{showSearchMsg === true ? 'No Search Results' : 'No Submitted Program Levels'}</div></div>
            </div>
          )
      }
    </div>
  </Fragment>
);

export default SmallGrid;