/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';
import LinkButton from '../../../../../common/components/buttons/LinkButton';
import Headings from '../../../../../common/components/headings/Headings';

import useWorkFlowNavigation from './UseWorkFlowNavigation';

import global from '../../../../../common/components/GlobalStyle.module.css';

const WorkFlowNavigation = ({ headerTitle, children }) => {
  const { navRoutes, onReturnClicked } = useWorkFlowNavigation();

  return (
    <Fragment>
      <div className="col-xs-12">
        <Headings.H3
          className={[global.NoBottomMargin, 'col-xs-12'].join(' ')}
          excludeBar={true}>
          {headerTitle}
        </Headings.H3>
      </div>
      <div className="col-xs-12">
        <hr className="usas-bar-turmeric" />
      </div>
      {navRoutes.WORKFLOWS_QUEUE_LIST?.route && <div className="col-xs-12 text-right usas-extra-bottom-margin">
        <LinkButton action={onReturnClicked}>Go back to Administrative Approvals list</LinkButton>
      </div>}
      <div className="col-xs-12">
        {children}
      </div>
    </Fragment>
  );
};

export default WorkFlowNavigation;