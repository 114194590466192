const ExistingClubRegistrationApprovalValidation = async (formState) => {
  let errors = {};

  if (formState.isRevisit === true && formState.stepNotes.trim() === '') {
    errors.stepNotes = "Notes is required when Requesting Additional Info";
  } else if (formState.stepNotes.length > 8000) {
    errors.stepNotes = "Notes cannot be more than 8000 characters long";
  }

  return errors ? errors : {};
};

export default ExistingClubRegistrationApprovalValidation;