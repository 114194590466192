import { useEffect, useState } from 'react';

import { isValidLink } from '../../../../common/components/grids/powerBiReport/utils/PowerBiReportValidation';
import usePowerBiReportsData from '../../../../common/state/powerBiReports/UsePowerBiReportsData';
import useForm from '../../../../common/utils/UseForm';

const INITIAL_FORM_STATE = {
  reportCategoryId: '',
  reportCategoryName: ''
}

const UseReporting = () => {
  const { powerBiReportState, getReportData, getReportsForCurrentUserData, clearObjData } = usePowerBiReportsData();
  const [showModal, setShowModal] = useState(false);
  const { formState, errorState, onValueTextPairChanged } = useForm(INITIAL_FORM_STATE);
  const [reportCategoryFilterState, setReportCategoryFilterState] = useState({ arrayData: [], isArrayLoaded: false });


  const onClick = (reportId, e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    getReportData(reportId);
  };

  const openReportInNewTab = (url) => {
    if (isValidLink(url)) {
      window.open(url, '_blank');
    } else {
      setShowModal(true);
    }
  };

  const hideModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (powerBiReportState.isObjLoaded === true
      && powerBiReportState.isObjLoading === false) {
      openReportInNewTab(powerBiReportState.objData.url);
      clearObjData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [powerBiReportState.isObjLoaded]);

  useEffect(() => {
    if (powerBiReportState.isArrayLoading === false
      && powerBiReportState.isArrayLoaded === false
      && powerBiReportState.message === '') {
      getReportsForCurrentUserData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [powerBiReportState.isArrayLoading, powerBiReportState.isArrayLoaded]);

  useEffect(() => {
    if (powerBiReportState?.isArrayLoaded === true && powerBiReportState?.isArrayLoading === false
      && powerBiReportState?.arrayData.length > 0 && reportCategoryFilterState?.isArrayLoaded === false) {
      let tempReportCategoryArray = [];
      powerBiReportState?.arrayData?.forEach(report => {
        if (Array.isArray(report?.rc) === true && report.rc?.length > 0) {
          const reportFilterObj = { id: report.ReportId, name: report.rc[0].ReportCategoryName };
          const isReportFilterAlreadyInObj = tempReportCategoryArray?.find(x => x.name === report.rc[0].ReportCategoryName);
          if (isReportFilterAlreadyInObj === undefined) {
            tempReportCategoryArray.push({ ...reportFilterObj });
          }
        };
      })

      setReportCategoryFilterState({
        ...reportCategoryFilterState,
        arrayData: tempReportCategoryArray,
        isArrayLoaded: true
      })
    }
  }, [powerBiReportState, reportCategoryFilterState]);

  return {
    powerBiReportState,
    onClick,
    showModal,
    hideModal,
    formState,
    errorState,
    onValueTextPairChanged,
    reportCategoryFilterState: reportCategoryFilterState?.arrayData
  };
};

export default UseReporting;