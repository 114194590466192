import { useEffect } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import { navLinks } from '../../../UseNavLinks';

import UsePersonClubTransferData from '../../../state/workflow/personClubTransfer/UsePersonClubTransferData';

import Constants from '../../../../common/utils/Constants';

const UseMemberClubTransfer = () => {
  const navigate = useNavigate();
  const { personClubTransferState, getPersonClubTransfer } = UsePersonClubTransferData();

  const onRequestClicked = (e, workflowId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    let targetRequest = null;
    for (const request of personClubTransferState.arrayData) {
      if (request.workflowId === workflowId) {
        targetRequest = request;
        break;
      }
    }

    if (targetRequest !== null) {
      navigate(navLinks.CLUB_TRANSFER_REQUEST_APPROVALS, { state: { targetRequest } });
    }
  };

  useEffect(() => {
    if (personClubTransferState.isArrayLoaded !== true) {
      getPersonClubTransfer();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    Constants,
    personClubTransferState,
    onRequestClicked
  };
};

export default UseMemberClubTransfer;