import { Fragment } from "react";
import Headings from "../../../../common/components/headings/Headings";
import RightArrowIcon from "../../../../common/components/icons/RightArrowIcon";

const MyReports = () => {
  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12 usas-extra-bottom-margin">
          <Headings.H3>Reporting Has Moved</Headings.H3>
        </div>
        <div className="col-xs-12 usas-extra-bottom-margin">
          If you are a Club looking for reports, go to Club &nbsp;<RightArrowIcon />&nbsp; My Club  &nbsp;<RightArrowIcon />&nbsp; Team Management &nbsp;<RightArrowIcon />&nbsp; Reports
        </div>
        <div className="col-xs-12 usas-extra-bottom-margin">
          If you are a LSC looking for reports, go to Reporting &nbsp;<RightArrowIcon />&nbsp; Lsc Reporting
        </div>
      </div>
    </Fragment>
  );
};

export default MyReports;

