import React, { Fragment } from 'react';

import useTimeBatchProcessContextView from './UseTimeBatchProcessContextView';
import navLinks from './NavLinks';

import Headings from '../../../common/components/headings/Headings';
import MobileContextBasedNav from '../../../common/components/contextBasedNav/MobileContextBasedNav';
import LargeContextBasedNav from '../../../common/components/contextBasedNav/LargeContextBasedNav';

import global from '../../../common/components/GlobalStyle.module.css';

const TimeBatchProcessContextView = ({ children }) => {
  const {
    onHomeClicked
  } = useTimeBatchProcessContextView();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <button className={global.HeaderButton} onClick={onHomeClicked}>
            <Headings.H3
              className={[global.NoBottomMargin, 'col-xs-12'].join(' ')}
              excludeBar={true}>
              Time Batch Process
            </Headings.H3>
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <hr className="usas-bar-turmeric" />
        </div>
      </div>
      <div className="row">
        <div className="visible-xs col-xs-12">
          <MobileContextBasedNav taxonomyName="BatchProcess" renderPillsOnly={true} />
        </div>
        <div className="hidden-xs col-xs-12">
          <LargeContextBasedNav taxonomyName="BatchProcess" homeRoute={navLinks.BATCH_TIME_RECOGNITIONS} renderPillsOnly={true} />
        </div>
      </div>
      {children}
    </Fragment>
  );
};

export default TimeBatchProcessContextView;