import React from 'react';

import useGroupScholasticAllAmericanInviteApproval from './UseGroupScholasticAllAmericanInviteApproval';

import WorkFlowNavigation from '../components/navigation/WorkFlowNavigation';
import GroupScholasticAllAmericanApprovalForm from '../components/forms/groupScholasticAllAmericanApprovalForm/GroupScholasticAllAmericanApprovalForm';
import WorkflowStepNotesPopup from '../components/dialogs/WorkflowStepNotesPopup';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

const GroupScholasticAllAmericanInviteApproval = () => {
  const {
    Constants,
    isLoading,
    isSaving,
    personOrgGroupId,
    readonlyFormState,
    modalState,
    onBackClicked,
    onApprovedClicked,
    onRejectClicked,
    onSendBackClicked,
    onSubmitFormCallback,
    onModalCancelClicked,
    onDownloadUploadedFile
  } = useGroupScholasticAllAmericanInviteApproval();

  return (
    <div className="row">
      <WorkFlowNavigation headerTitle={"Scholastic All America Group Invite"}>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <GroupScholasticAllAmericanApprovalForm
            personOrgGroupId={personOrgGroupId}
            readonlyFormState={readonlyFormState}
            onApprovedClicked={onApprovedClicked}
            onBackClicked={onBackClicked}
            onRejectClicked={onRejectClicked}
            onSendBackClicked={onSendBackClicked}
            onDownloadUploadedFile={onDownloadUploadedFile} />
        </div>
      </WorkFlowNavigation>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
      {modalState.displayModal === true &&
        <WorkflowStepNotesPopup
          title={modalState.modalTitle}
          submitButtonText={modalState.submitButtonText}
          notesLabel={modalState.notesLabel}
          onSubmitFormCallback={onSubmitFormCallback}
          onModalCancelClicked={onModalCancelClicked} />
      }
    </div>
  );
};

export default GroupScholasticAllAmericanInviteApproval;