import React, { Fragment } from 'react';

import useClubRecognitionProgramLevelApprovals from './UseClubRecognitionProgramLevelApprovals';

import WorkFlowNavigation from '../components/navigation/WorkFlowNavigation';

import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import LeftArrowIcon from '../../../../common/components/icons/LeftArrowIcon';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import global from '../../../../common/components/GlobalStyle.module.css';

const ClubRecognitionProgramLevelApprovals = () => {
  const {
    Constants,
    clubRecognitionState,
    programLevelState,
    state,
    onApproveClicked,
    onCancelClicked,
    onBackClicked
  } = useClubRecognitionProgramLevelApprovals();

  return (
    <div className="row">
      <WorkFlowNavigation headerTitle={programLevelState.objData !== undefined && programLevelState.objData?.levelName ?
        'Club Recognition Program - Certify ' + programLevelState.objData?.levelName : 'Club Recognition Program'}>
        <div className="row">
          <div className="col-xs-12">
            <button className={global.BackToSearchButton}
              type="button"
              onClick={onBackClicked}>
              <LeftArrowIcon /> Back to Club Recognition Program Queue
            </button>
          </div>
        </div>
        <div className="row">
          <div className={["col-xs-12", global.HeaderText].join(' ')}>
            <p><b>LSC Code/Club Code:</b> {state.qualifiedOrgUnitCode}</p>
            <p><b>Club Name:</b> {state.clubName}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <hr className={[global.ThinBar, 'usas-bar-gray'].join(' ')} />
          </div>
        </div>
        <div className="row">
          <div className={["col-xs-12", global.HeaderText].join(' ')}>
            <p><b>{programLevelState.objData?.levelName} Current Points / Minimum Points:</b> {programLevelState.objData?.currentPointValue} / {programLevelState.objData?.minimumValue}</p>
            {Array.isArray(programLevelState.objData?.programCategory) && programLevelState.objData?.programCategory.map((programCategory, i) => {
              return (
                <p key={i}><b>{programCategory.categoryName} Current Points / Minimum Points:</b> {programCategory.currentPointValue} / {programCategory.minimumValue}</p>
              )
            })}
            <p><b>Submission Date:</b> {state.submissionDate}</p>
          </div>
        </div>
        {state.error ?
          <div className="row">
            <div className="col-xs-12" >
              <p className={global.ErrorMessage}>{state.error}</p>
            </div>
          </div> : <Fragment />}
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12">
            <PrimaryButton type="button" onClick={onApproveClicked}>Approve</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onCancelClicked}>Back</SecondaryButton>
          </div>
        </div>
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.LOADING_MSG}
          displayPopUp={clubRecognitionState.isObjLoading} />
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.SAVING_MSG}
          displayPopUp={clubRecognitionState.isSaving} />
      </WorkFlowNavigation>
    </div>
  );
};

export default ClubRecognitionProgramLevelApprovals;