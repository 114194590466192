import React, { Fragment } from 'react';

import LearnToSwimRegistrationGridLarge from './LearnToSwimRegistrationGridLarge';
import LearnToSwimRegistrationGridSmall from './LearnToSwimRegistrationGridSmall';

const LearnToSwimRegistrationGrid = ({ data, isLoading, onRegistrationClicked }) => (
  <Fragment>
    <LearnToSwimRegistrationGridLarge data={data} isLoading={isLoading} onRegistrationClicked={onRegistrationClicked} />
    <LearnToSwimRegistrationGridSmall data={data} isLoading={isLoading} onRegistrationClicked={onRegistrationClicked} />
  </Fragment>
);

export default LearnToSwimRegistrationGrid;