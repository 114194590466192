/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import usePreCompetitiveRegistrationApproval from './UsePreCompetitiveRegistrationApproval';

import WorkFlowNavigation from '../components/navigation/WorkFlowNavigation';

import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import ReadOnly from '../../../../common/components/readOnly/ReadOnly';

import { formatDate } from '../../../../common/utils/DateFunctions';

const PreCompetitiveRegistrationApproval = () => {
  const {
    Constants,
    isLoading,
    isSaving,
    preCompetitiveRegistrationState,
    preCompRegObjState,
    onBackClicked,
    onApprovedClicked,
    onRejectClicked
  } = usePreCompetitiveRegistrationApproval();

  return (
    <div className="row">
      <WorkFlowNavigation headerTitle={"Pre-Competitive Registration"}>
        {preCompetitiveRegistrationState.isObjLoaded === true &&
          <form noValidate>
            <div className="row">
              {/*TODO add back once reporting is done <div className="col-xs-12 col-sm-6 col-sm-push-6">
                <div className="col-xs-12 col-sm-6">
                  <ActionIntraPageButton type="button" onClick={() => { }}>{LocalConstants.WORKFLOW_APPLICATION_TEXT}</ActionIntraPageButton>
                </div>
              </div> */}
              <div className="col-xs-12 col-sm-6">
                <div className="col-xs-12">
                  <ReadOnly
                    label="Pre-Competitive Name: "
                    name="locationName"
                    value={preCompRegObjState.locationName} />
                </div>
                <div className="col-xs-12">
                  <ReadOnly
                    label="Submission Date: "
                    name="submissionDate"
                    value={formatDate(preCompRegObjState.submissionDate)} />
                </div>
              </div>
            </div>
            {/*TODO add back when pre comp address logic is figured out 
            <div className="row">
              <div className="col-xs-12">
                <ReadOnly
                  label="Address: "
                  name="address"
                  value={preCompRegObjState.address} />
              </div>
            </div> */}
            <div className="row usas-extra-top-margin usas-extra-bottom-margin">
              <div className="col-xs-12">
                <PrimaryButton type="button" onClick={onApprovedClicked}>Approve</PrimaryButton>&nbsp;
                <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>&nbsp;
                <PrimaryButton type="button" onClick={onRejectClicked}>Reject</PrimaryButton>
              </div>
            </div>
          </form>
        }
      </WorkFlowNavigation>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </div>
  );
};

export default PreCompetitiveRegistrationApproval;