import { useState } from 'react';

import TimeBatchProcessData from './TimeBatchProcessData';

const useTimeBatchProcessData = () => {
  const [timeBatchProcessRunLogState, setTimeBatchProcessRunLogState] = useState(TimeBatchProcessData.INITIAL_STATE);

  const calculateTimeRecognitions = (processObject) => {
    const processedObject = JSON.stringify(processObject);

    TimeBatchProcessData.calculateTimeRecognitions(processedObject, timeBatchProcessRunLogState, setTimeBatchProcessRunLogState);
  };

  const calculateTimeRecords = () => {
    TimeBatchProcessData.calculateTimeRecords(timeBatchProcessRunLogState, setTimeBatchProcessRunLogState);
  };

  const getCalculateTimeRecordsRunLog = (processType) => {
    TimeBatchProcessData.getCalculateTimeRecordsRunLog(processType, timeBatchProcessRunLogState, setTimeBatchProcessRunLogState);
  }

  const deleteTimeRecordsRunLog = (processType) => {
    TimeBatchProcessData.deleteTimeRecordsRunLog(processType, timeBatchProcessRunLogState, setTimeBatchProcessRunLogState);
  }

  return {
    timeBatchProcessRunLogState,
    calculateTimeRecognitions,
    calculateTimeRecords,
    getCalculateTimeRecordsRunLog,
    deleteTimeRecordsRunLog
  };
};

export default useTimeBatchProcessData;