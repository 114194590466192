import React, { Fragment } from 'react';

import LargeGrid from './LargeGrid';
import SmallGrid from './SmallGrid';

const Grid = ({ approvalQueues }) => {
  return (
    <Fragment>
      <LargeGrid approvalQueues={approvalQueues} />
      <SmallGrid approvalQueues={approvalQueues} />
    </Fragment>
  );
};

export default Grid;