const MeetSanctionApprovalValidation = async (formState) => {
  let errors = {};

  if (formState.meetSanctionNumber.trim() === '') {
    errors.meetSanctionNumber = 'Sanction Number is required';
  } else if (formState.meetSanctionNumber.trim().length > 20) {
    errors.meetSanctionNumber = 'Sanction Number cannot exceed 20 characters';
  }

  return errors ? errors : {};
};

export default MeetSanctionApprovalValidation;