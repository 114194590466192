import { useEffect } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import { navLinks } from '../../../UseNavLinks';

import useExistingClubRegistrationData from '../../../state/workflow/existingClubRegistration/UseExistingClubRegistrationData';

import Constants from '../../../../common/utils/Constants';

const useExistingClubRegistration = () => {
  const navigate = useNavigate();
  const { existingClubRegistrationState, getExistingClubRegistration } = useExistingClubRegistrationData();

  const onRegistrationClicked = (e, clubReg) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(navLinks.EXISTING_CLUB_REGISTRATION_APPROVALS, { state: { clubReg } });
  };

  useEffect(() => {
    if (existingClubRegistrationState.isArrayLoaded !== true) {
      getExistingClubRegistration();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    Constants,
    existingClubRegistrationState,
    onRegistrationClicked
  };
};

export default useExistingClubRegistration;