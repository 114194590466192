import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "../../../../common/wrappers/ReactRouterDom";

import { navLinks } from '../../../UseNavLinks';
import { createNewWorkflowObj } from '../utils/WorkflowsUtils';

import useGroupNationalTeamInviteData from "../../../state/workflow/groupNationalTeamInvite/UseGroupNationalTeamInviteData";

import useEnvironmentVariableData from "../../../../common/state/environmentVariable/UseEnvironmentVariableData";
import useOrgGroupPropertiesData from "../../../../common/state/orgGroupProperties/UseOrgGroupPropertiesData";
import useGroupFieldNamesData from "../../../../common/state/groupFieldNames/UseGroupFieldNamesData";

import Constants from '../../../../common/utils/Constants';

const useGroupNationalTeamInviteApproval = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { GROUP_CONSTANTS } = useGroupFieldNamesData();
  const { environmentVariableState, NATIONAL_TEAM_GROUP_TYPE_ID } = useEnvironmentVariableData();
  const { orgGroupPropertiesState, getOrgGroupProperties } = useOrgGroupPropertiesData();
  const { groupNationalTeamInviteState, getGroupNationalTeamInviteWorkflow, putGroupNationalTeamInviteWorkflow
  } = useGroupNationalTeamInviteData();
  const [readonlyFormState, setReadonlyFormState] = useState(getInitialReadonlyFormState);

  const onApprovedClicked = () => {
    const workflowObjCopy = JSON.parse(JSON.stringify(groupNationalTeamInviteState.objData));
    const groupInvite = location.state.groupInvite;

    putGroupNationalTeamInviteWorkflow(groupInvite.workflowId,
      createNewWorkflowObj(workflowObjCopy, groupInvite.workflowStepId, Constants.WORKFLOW_STATUS_APPROVED,
        '', 0));
  };

  const onRejectClicked = () => {
    const workflowObjCopy = JSON.parse(JSON.stringify(groupNationalTeamInviteState.objData));
    const groupInvite = location.state.groupInvite;

    putGroupNationalTeamInviteWorkflow(groupInvite.workflowId,
      createNewWorkflowObj(workflowObjCopy, groupInvite.workflowStepId, Constants.WORKFLOW_STATUS_REJECTED,
        '', 0));
  };

  const onBackClicked = () => {
    navigate(navLinks.GROUP_NATIONAL_TEAM_INVITE);
  };

  useEffect(() => {
    if (location.state?.groupInvite?.workflowId > 0) {
      getGroupNationalTeamInviteWorkflow(location.state.groupInvite.workflowId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  useEffect(() => {
    if (environmentVariableState.isLoaded === true) {
      if (NATIONAL_TEAM_GROUP_TYPE_ID !== orgGroupPropertiesState.groupTypeId) {
        getOrgGroupProperties(NATIONAL_TEAM_GROUP_TYPE_ID);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgGroupPropertiesState, environmentVariableState]);

  useEffect(() => {
    if (location.state?.groupInvite && NATIONAL_TEAM_GROUP_TYPE_ID === orgGroupPropertiesState.groupTypeId
      && orgGroupPropertiesState.isArrayLoading === false) {
      const groupInvite = location.state.groupInvite;

      setReadonlyFormState({
        ...readonlyFormState,
        personName: `${groupInvite.firstName || ''} ${groupInvite.lastName || ''}`,
        groupName: groupInvite.groupName || '',
        groupAttendeeTypeName: groupInvite.personGroupTypeField.find(x => x.groupTypeFieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_ATTENDEE_TYPE)?.fieldValue || '',
        groupRoleTypeName: groupInvite.personGroupTypeField.find(x => x.groupTypeFieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_ROLE_TYPE)?.fieldValue || '',
        professionalSwimmer: groupInvite.personGroupTypeField.find(x => x.groupTypeFieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_PROFESSIONAL_SWIMMER)?.fieldValue || '',
        cityOfBirth: groupInvite.personGroupTypeField.find(x => x.groupTypeFieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_CITY_OF_BIRTH)?.fieldValue || '',
        countryOfBirth: groupInvite.personGroupTypeField.find(x => x.groupTypeFieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_COUNTRY_OF_BIRTH)?.fieldValue || '',
        fanMailAddress: groupInvite.personGroupTypeField.find(x => x.groupTypeFieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_FAN_MAIL_ADDRESS)?.fieldValue || '',
        fanMailCity: groupInvite.personGroupTypeField.find(x => x.groupTypeFieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_FAN_MAIL_CITY)?.fieldValue || '',
        fanMailStateCode: groupInvite.personGroupTypeField.find(x => x.groupTypeFieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_FAN_MAIL_STATE)?.fieldValue || '',
        fanMailZip: groupInvite.personGroupTypeField.find(x => x.groupTypeFieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_FAN_MAIL_ZIP)?.fieldValue || '',
        passportNumber: groupInvite.personGroupTypeField.find(x => x.groupTypeFieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_PASSPORT_NUMBER)?.fieldValue || '',
        passportEffectiveDate: groupInvite.personGroupTypeField.find(x => x.groupTypeFieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_PASSPORT_EFFECTIVE_DATE)?.fieldValue || Constants.BLANK_DATE_STRING,
        passportExpirationDate: groupInvite.personGroupTypeField.find(x => x.groupTypeFieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_PASSPORT_EXPIRATION_DATE)?.fieldValue || Constants.BLANK_DATE_STRING,
        knownTravelerNumber: groupInvite.personGroupTypeField.find(x => x.groupTypeFieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_KNOWN_TRAVELER_NUMBER)?.fieldValue || '',
        preferredAirlineName: groupInvite.personGroupTypeField.find(x => x.groupTypeFieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_PREFERRED_AIRLINE)?.fieldValue || '',
        aisleWindowPreferenceName: groupInvite.personGroupTypeField.find(x => x.groupTypeFieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_AISLE_WINDOW_PREFERENCE)?.fieldValue || '',
        airlineStatus: groupInvite.personGroupTypeField.find(x => x.groupTypeFieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_AIRLINE_STATUS)?.fieldValue || '',
        primaryHealthProvider: groupInvite.personGroupTypeField.find(x => x.groupTypeFieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_PRIMARY_HEALTH_PROVIDER)?.fieldValue || '',
        agent: groupInvite.personGroupTypeField.find(x => x.groupTypeFieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_AGENT)?.fieldValue || '',
        highSchoolName: groupInvite.personGroupTypeField.find(x => x.groupTypeFieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_HIGH_SCHOOL)?.fieldValue || '',
        highSchoolGraduationYear: groupInvite.personGroupTypeField.find(x => x.groupTypeFieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_HIGH_SCHOOL_GRADUATION_YEAR)?.fieldValue || '',
        collegeName: groupInvite.personGroupTypeField.find(x => x.groupTypeFieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_COLLEGE)?.fieldValue || '',
        collegeGraduationYear: groupInvite.personGroupTypeField.find(x => x.groupTypeFieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_COLLEGE_GRADUATION_YEAR)?.fieldValue || ''
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state, orgGroupPropertiesState]);

  useEffect(() => {
    if (groupNationalTeamInviteState.isSaved === true) {
      navigate(navLinks.GROUP_NATIONAL_TEAM_INVITE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupNationalTeamInviteState.isSaved]);

  function getInitialReadonlyFormState() {
    return {
      personName: '',
      groupName: '',
      groupAttendeeTypeName: '',
      groupRoleTypeName: '',
      professionalSwimmer: '',
      cityOfBirth: '',
      countryOfBirth: '',
      fanMailAddress: '',
      fanMailCity: '',
      fanMailStateCode: '',
      fanMailStateName: '',
      fanMailZip: '',
      passportNumber: '',
      passportEffectiveDate: Constants.BLANK_DATE_STRING,
      passportExpirationDate: Constants.BLANK_DATE_STRING,
      knownTravelerNumber: '',
      preferredAirlineName: '',
      aisleWindowPreferenceName: '',
      airlineStatus: '',
      primaryHealthProvider: '',
      agent: '',
      highSchoolName: '',
      highSchoolGraduationYear: '',
      collegeName: '',
      collegeGraduationYear: ''
    };
  };

  return {
    Constants,
    isSaving: groupNationalTeamInviteState.isSaving,
    isLoading: groupNationalTeamInviteState.isObjLoading || environmentVariableState.isLoading || orgGroupPropertiesState.isArrayLoading,
    readonlyFormState,
    onApprovedClicked,
    onRejectClicked,
    onBackClicked
  };
};

export default useGroupNationalTeamInviteApproval;