import React, { Fragment } from 'react';

import LargeGrid from './LargeGrid';
import SmallGrid from './SmallGrid';

const ClubRegistrationGrid = ({data, isLoading, onRegistrationClicked}) => (
  <Fragment>
    <LargeGrid data={data} isLoading={isLoading} onRegistrationClicked={onRegistrationClicked}/>
    <SmallGrid data={data} isLoading={isLoading} onRegistrationClicked={onRegistrationClicked}/>
  </Fragment>
);

export default ClubRegistrationGrid;