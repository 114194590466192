import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "../../../../common/wrappers/ReactRouterDom";

import { createNewWorkflowObj } from '../utils/WorkflowsUtils';

import { navLinks } from '../../../UseNavLinks';

import useLearnToSwimRegistrationData from "../../../state/workflow/learnToSwimRegistration/UseLearnToSwimRegistrationData";

import Constants from '../../../../common/utils/Constants';

const INITIAL_STATE = {
  learnToSwimReg: {}
};

const useLearnToSwimRegistrationApproval = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { ltsProviderRegistrationState, getLTSProviderRegistrationWorkflow, putLTSProviderRegistrationWorkflow
  } = useLearnToSwimRegistrationData();
  const [state, setState] = useState(INITIAL_STATE);

  const onApprovedClicked = () => {
    const workflowObjCopy = JSON.parse(JSON.stringify(ltsProviderRegistrationState.objData));

    putLTSProviderRegistrationWorkflow(state.learnToSwimReg.workflowId,
      createNewWorkflowObj(workflowObjCopy, state.learnToSwimReg.workflowStepId, Constants.WORKFLOW_STATUS_APPROVED,
        '', 0));
  };

  const onRejectClicked = () => {
    const workflowObjCopy = JSON.parse(JSON.stringify(ltsProviderRegistrationState.objData));

    putLTSProviderRegistrationWorkflow(state.learnToSwimReg.workflowId,
      createNewWorkflowObj(workflowObjCopy, state.learnToSwimReg.workflowStepId, Constants.WORKFLOW_STATUS_REJECTED,
        '', 0));
  };

  const onBackClicked = () => {
    navigate(navLinks.LEARN_TO_SWIM_REGISTRATION);
  };

  useEffect(() => {
    if (location.state?.learnToSwimReg?.workflowId > 0) {
      setState({
        ...state,
        learnToSwimReg: location.state.learnToSwimReg
      });

      getLTSProviderRegistrationWorkflow(location.state.learnToSwimReg.workflowId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (ltsProviderRegistrationState.isSaved === true) {
      navigate(navLinks.LEARN_TO_SWIM_REGISTRATION);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ltsProviderRegistrationState.isSaved]);

  return {
    isSaving: ltsProviderRegistrationState.isSaving,
    isLoading: ltsProviderRegistrationState.isObjLoading,
    learnToSwimRegObj: state.learnToSwimReg,
    ltsProviderRegistrationState,
    onApprovedClicked,
    onRejectClicked,
    onBackClicked
  };
};

export default useLearnToSwimRegistrationApproval;