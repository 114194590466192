import React from 'react';

import useMeetSanction from './UseMeetSanction';

import WorkFlowNavigation from '../components/navigation/WorkFlowNavigation';
import MeetSanctionGrid from '../components/grids/meetSanctionGrid/MeetSanctionGrid';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

const MeetSanction = () => {
  const {
    Constants,
    meetSanctionState,
    onSanctionClicked
  } = useMeetSanction();

  return (
    <div className="row">
      <WorkFlowNavigation headerTitle={"Meet Sanction Request Queue"}>
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <MeetSanctionGrid
              data={meetSanctionState.arrayData}
              isLoading={meetSanctionState.isArrayLoading}
              onSanctionClicked={onSanctionClicked} />
          </div>
        </div>
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.LOADING_MSG}
          displayPopUp={meetSanctionState.isArrayLoading} />
      </WorkFlowNavigation>
    </div>
  );
};

export default MeetSanction;