
const createNewWorkflowObj = (workflowObjCopy, workflowStepId, stepStatus, stepNotes, personId) => {  
  const workflowStep = workflowObjCopy.workflowStep.find(step => step.workflowStepId === workflowStepId);

  workflowStep.stepStatus = stepStatus;
  workflowStep.stepNotes = stepNotes;
  workflowStep.completedById = personId;

  return workflowObjCopy;
};

export { createNewWorkflowObj };