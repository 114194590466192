import { BASIC_INITIAL_STATE } from '../../../../common/utils/HttpHelper';

import getNewClubRegistration from './GetNewClubRegistrationData';
import getNewClubRegistrationWorkflow from '../GetWorkFlowByIdData';
import putNewClubRegistrationWorkflow from '../PutWorkFlowData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const NewClubRegistrationData = {
  INITIAL_STATE,
  getNewClubRegistration,
  getNewClubRegistrationWorkflow,
  putNewClubRegistrationWorkflow
};

export default NewClubRegistrationData;