import React, { Fragment } from 'react';
import Constants from '../../../utils/Constants';
import PopUpModal from '../../dialogs/PopUpModal';

import PowerBiReportGridLarge from './PowerBiReportGridLarge';
import PowerBiReportGridSmall from './PowerBiReportGridSmall';

const PowerBiReportGrid = ({ state, onClick, showModal, hideModal, formState }) => {

  return (
    <Fragment>
      <PowerBiReportGridLarge
        state={state}
        onClick={onClick}
        formState={formState}
      />
      <PowerBiReportGridSmall
        state={state}
        onClick={onClick}
        formState={formState}
      />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={'No Report Found'}
        onModalCanceled={hideModal}
        displayPopUp={showModal} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={state.isObjLoading} />
    </Fragment>
  )
};

export default PowerBiReportGrid;